import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Card,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import Deposit from "../../../assets/Dashboard/Deposit.png";
import Referral from "../../../assets/Dashboard/Referral.png";
import Withdraw from "../../../assets/Dashboard/Withdraw.png";
import UniqueVisitorCard from "../components/Chart/UniqueVisitorCard";
import AppHeader from "../components/Layout/AppHeader";
import Widget from "../components/Widget/Widget";
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { post } from "utils/axios";
import { getProfileData } from "utils/auth/getToken";

ChartJS.register(
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [apiData, setApiData] = useState(null); // To store the API data
  const [loading, setLoading] = useState(true); // Loading state

  const pieData = apiData
    ? {
        labels: ["Deposit", "Profit"],
        datasets: [
          {
            data: [apiData.total_amount, apiData.weekly_profit_amount], // Using the values from API
            backgroundColor: ["#5A8DEE", "#A8D8EA"],
            hoverBackgroundColor: ["#5A8DEE", "#A8D8EA"],
            borderWidth: 1,
          },
        ],
      }
    : {
        labels: ["Deposit", "Profit"],
        datasets: [
          {
            data: [63, 25], // Default values before API data loads
            backgroundColor: ["#5A8DEE", "#A8D8EA"],
            hoverBackgroundColor: ["#5A8DEE", "#A8D8EA"],
            borderWidth: 1,
          },
        ],
      };

  // Options for Pie Chart (optional)
  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // Fetch API data
  useEffect(() => {
    const fetchProfitReport = async () => {
      try {
        const response = await post("/profit_report_weekly");
        setApiData(response.data.details[0]); // Store the first item in details
        setLoading(false); // Disable loading after data is fetched
      } catch (error) {
        console.error("Error fetching profit report:", error);
        setLoading(false); // Disable loading if an error occurs
      }
    };

    fetchProfitReport();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>; // Show loading state
  }

  const {
    total_deposit,
    total_withdrawn,
    total_referral_earning,
    total_amount,
  } = getProfileData();

  return (
    <Box>
      <AppHeader breadcrumbs="Dashboard" page="Main Dashboard" />

      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <Widget
            name="Total Deposit"
            value={`$${total_deposit}`}
            image={Deposit}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Widget
            name="Total Withdraw"
            value={`$${total_withdrawn}`}
            image={Withdraw}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Widget
            name="Referrel Earning"
            value={`$${total_referral_earning}`}
            image={Withdraw}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Widget
            name="Trading Balance"
            value={`$${total_amount}`}
            image={Referral}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              padding: 3,
              borderRadius: "15px",
              boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Grid container spacing={2} alignItems="start">
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "30px",
                      fontWeight: "600",
                      color: "#2B3674",
                      fontFamily: "Poppins",
                    }}
                  >
                    ${apiData ? apiData.total_amount : "N/A"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#A3AED0",
                      fontFamily: "Poppins",
                    }}
                  >
                    Total Profit
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} sx={{ py: 3 }}>
                <Box sx={{ width: 160, height: 160 }}>
                  <Pie data={pieData} options={pieOptions} />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              padding: 3,
              borderRadius: "15px",
              boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <UniqueVisitorCard />
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} py={2}>
        <Grid item md={6}>
          <TableContainer
            component={Card}
            sx={{
              pl: 1,
              borderRadius: "15px",
              boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: 600,
                color: "#2B3674",
                p: 2,
                fontFamily: "Poppins",
              }}
            >
              Current Week Revenue
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      color: "#A3AED0",
                      fontFamily: "Poppins",
                    }}
                  >
                    Week Start
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      color: "#A3AED0",
                      fontFamily: "Poppins",
                    }}
                  >
                    Average Profit Rate
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      color: "#A3AED0",
                      fontFamily: "Poppins",
                    }}
                  >
                    Profit Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      color: "#A3AED0",
                      fontFamily: "Poppins",
                    }}
                  >
                    Total Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apiData && (
                  <TableRow>
                    <TableCell>{apiData.week_start}</TableCell>
                    <TableCell>{apiData.avg_profit_rate}%</TableCell>
                    <TableCell>${apiData.weekly_profit_amount}</TableCell>
                    <TableCell>${apiData.total_amount}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
