import { Alert, Card, Snackbar, TextField, Typography } from "@mui/material";
import { ReactComponent as HeroImage } from "../../../../assets/Home/SVGs/HeroImage.svg";
import { Button } from "components/shared";
import HeroBackground from "assets/Home/SVGs/HeroBackground.svg";
import { getProfileData } from "utils/auth/getToken";
import { useState } from "react";

const Link = () => {
  const { referal_code } = getProfileData();

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Copy to clipboard with Snackbar feedback
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setSnackbarMessage("Copied to clipboard!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setSnackbarMessage("Failed to copy text.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  // Handle Snackbar close
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div className="hidden md:block">
        <Card
          sx={{
            mt: 3,
            borderRadius: 4,
            boxShadow: "none",
            p: 2,
            backgroundColor: "#FFFFFF",
            height: "100%", // Ensure it fills the available height
          }}
          style={{
            backgroundImage: `url(${HeroBackground})`,
            backgroundSize: "contain", // Ensure the image covers the entire area
            backgroundPosition: "center", // Center the image
            backgroundRepeat: "no-repeat", // Don't repeat the image
            width: "100%", // Ensure it takes up the full width
            minHeight: "100vh", // Make sure it covers the full viewport height
          }}
        >
          <div className="flex flex-col pt-10 items-center md:flex-row px-20">
            {/* Text Section */}
            <div className="flex flex-col gap-6 w-full md:w-1/2 md:flex-grow">
              <Typography variant="h4" className="font-bold text-darkblue-600">
                Invite Your Friend And Earn Extra Bonus
              </Typography>
              <Typography variant="body1" className="text-lg">
                Welcome to our Invite & Earn program! We&apos;re thrilled to
                have you on board and excited to reward you for sharing the
                benefits of our platform with your friends. It&apos;s simple -
                the more friends you bring in, the more rewards you unlock!
              </Typography>
              <Typography variant="body1" className="text-lg text-blue-500">
                You can share your referral link
              </Typography>
              <div className="flex flex-col md:flex-row gap-4 md:gap-0">
                <TextField
                  placeholder={`https://www.fxtrado.com/user/register/r/${referal_code}`}
                  className="w-full"
                  aria-readonly
                  InputProps={{
                    readOnly: true,
                    className: "md:!rounded-r-none",
                    sx: {
                      border: "none",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  className="md:!rounded-l-none"
                  color="secondary"
                  onClick={() =>
                    copyToClipboard(
                      `https://www.fxtrado.com/user/register/r/${referal_code}`
                    )
                  }
                >
                  Copy
                </Button>
              </div>
              <Typography variant="body1" className="text-lg text-blue-500">
                Your referral Code :
              </Typography>
              <div className="flex flex-col md:flex-row gap-4 md:gap-0">
                <TextField
                  placeholder={referal_code}
                  className="w-full md:max-w-fit"
                  aria-readonly
                  InputProps={{
                    readOnly: true,
                    className: "md:!rounded-r-none",
                    sx: {
                      border: "none",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  className="md:!rounded-l-none"
                  color="secondary"
                  onClick={() => copyToClipboard(referal_code)}
                >
                  Copy
                </Button>
              </div>
            </div>

            {/* Image Section */}
            <div className="w-full md:w-1/2 flex justify-center">
              <HeroImage className="w-full md:w-auto" />
            </div>
          </div>
        </Card>
      </div>
      <div className="block md:hidden">
        <Card
          sx={{
            mt: 3,
            borderRadius: 4,
            boxShadow: "none",
            p: 2,
            backgroundColor: "#FFFFFF",
            height: "100%", // Ensure it fills the available height
          }}
          // style={{
          //   backgroundImage: `url(${HeroBackground})`,
          //   backgroundSize: "contain", // Ensure the image covers the entire area
          //   backgroundPosition: "center", // Center the image
          //   backgroundRepeat: "no-repeat", // Don't repeat the image
          //   width: "100%", // Ensure it takes up the full width
          //   minHeight: "100vh", // Make sure it covers the full viewport height
          // }}
        >
          <div className="flex flex-col items-center md:pt-10 pt-10 px-5 md:flex-row md:px-20 h-fit">
            <div className="flex flex-col gap-6 w-full">
              {/* Heading text with responsive font sizes */}
              <Typography
                variant="h4"
                className="font-bold text-darkblue-600 max-w-sm text-2xl md:text-4xl"
              >
                Invite Your Friend And Earn Extra Bonus
              </Typography>

              {/* Body text with responsive font sizes */}
              <Typography
                variant="body1"
                className="text-base md:text-lg max-w-md"
              >
                Welcome to our Invite & Earn program! We&apos;re thrilled to
                have you on board and excited to reward you for sharing the
                benefits of our platform with your friends. It&apos;s simple -
                the more friends you bring in, the more rewards you unlock!
              </Typography>

              {/* Input and Button */}
              <Typography variant="body1" className="text-lg text-blue-500">
                You can share your referral link
              </Typography>
              <div className="flex flex-col md:flex-row gap-4 md:gap-0">
                <TextField
                  placeholder={`https://www.fxtrado.com/user/register/r/${referal_code}`}
                  className="w-full"
                  aria-readonly
                  InputProps={{
                    readOnly: true,
                    className: "md:!rounded-r-none",
                    sx: {
                      border: "none",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  className="md:!rounded-l-none"
                  color="secondary"
                  onClick={() =>
                    copyToClipboard(
                      `https://www.fxtrado.com/user/register/r/${referal_code}`
                    )
                  }
                >
                  Copy
                </Button>
              </div>
              <Typography variant="body1" className="text-lg text-blue-500">
                Your referral Code :
              </Typography>
              <div className="flex flex-col md:flex-row gap-4 md:gap-0">
                <TextField
                  placeholder={referal_code}
                  className="w-full md:max-w-fit"
                  aria-readonly
                  InputProps={{
                    readOnly: true,
                    className: "md:!rounded-r-none",
                    sx: {
                      border: "none",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  className="md:!rounded-l-none"
                  color="secondary"
                  onClick={() => copyToClipboard(referal_code)}
                >
                  Copy
                </Button>
              </div>
            </div>

            {/* Hero Image with responsive width */}
            <div>
              <HeroImage className="w-dvw md:w-auto" />
            </div>
          </div>
        </Card>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Link;
