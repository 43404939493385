import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "utils/axios";

const useProfileHistory = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await post("/profit_report");
        const data = response.details;

        const formattedRows = data.map((item, index) => ({
          id: index + 1,
          balance: item.balance,
          profit_amount: item.profit_amount,
          profit_rate: item.profit_rate,
          date: item.date,
        }));

        setRows(formattedRows);
      } catch (error) {
        console.error("Error fetching profit report:", error);
      }
    };

    fetchData();
  }, []);

  console.log(rows);

  const columns = [
    {
      field: "id",
      headerName: "No",
      flex: 1,
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      valueGetter: (params) => {
        return `$ ${params}`;
      },
    },
    {
      field: "profit_amount",
      headerName: "Profit Amount",
      flex: 1,
    },
    {
      field: "profit_rate",
      headerName: "Profit Rate",
      flex: 1,
      valueGetter: (params) => {
        return `${params}%`;
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
  ];

  const navigate = useNavigate();
  const handleClick = (page) => {
    if (page === "deposit") {
      navigate("/dashboard/financial-operation/deposit");
    } else if (page === "withdraw") {
      navigate("/dashboard/financial-operation/withdraw");
    }
  };

  return {
    rows,
    columns,
    handleClick,
  };
};
export default useProfileHistory;
