import { Card, TableContainer } from "@mui/material";
import useDeposit from "components/Dashboard/Financial-Operation/components/Deposit/useDeposit";
import { DataGrid } from "components/shared";

const Team = () => {
  const { columns, rows } = useDeposit();
  return (
    <div>
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <TableContainer
          sx={{
            pl: 1,
            borderRadius: "15px",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows ?? []}
            loading={false}
            tableName="Level 1"
            fromDate="01/10/2024"
            toDate="01/10/2024"
          />
        </TableContainer>
      </Card>
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <TableContainer
          sx={{
            pl: 1,
            borderRadius: "15px",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows ?? []}
            loading={false}
            tableName="Level 2"
            fromDate="01/10/2024"
            toDate="01/10/2024"
          />
        </TableContainer>
      </Card>
    </div>
  );
};

export default Team;
