import React, { createContext, useContext, useEffect, useState } from "react";
import { post } from "utils/axios";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: sessionStorage.getItem("token"),
    userType: sessionStorage.getItem("usertype"),
  });

  const get_user_fund_detail = async (token) => {
    const { data } = await post("/user_fund_detail", { authToken: token });
    Object.entries(data).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
  };

  // Sync auth state with sessionStorage on initial load
  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    const storedUserType = sessionStorage.getItem("usertype");

    if (storedToken && storedUserType) {
      setAuth({ token: storedToken, userType: storedUserType });
      get_user_fund_detail(storedToken);
    }
  }, []);

  const login = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });

    setAuth({
      token: data.token,
      userType: data.usertype,
    });
  };

  const logout = () => {
    sessionStorage.clear();
    setAuth({ token: null, userType: null });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
