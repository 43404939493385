// AdminDashboard.js
import { Menu, MenuItem, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Card,
  Typography,
  Avatar,
  Snackbar,
  Alert,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import { MoreVert } from "@mui/icons-material";
import AppHeader from "components/Dashboard/components/Layout/AppHeader";
import useAdminDashboard from "./useAdminDashboard";
import { getProfileData } from "utils/auth/getToken";
import { Button, DatePicker, TextField } from "components/shared";
import { useMutation, useQuery } from "react-query";
import { post } from "utils/axios";
import { Field, Form, Formik } from "formik";

const AdminDashboard = () => {
  const { user_count, total_fund_amount } = getProfileData();
  const {
    fetchNewUserRequest,
    fetchPendingFundRequest,
    handleFundAccept,
    snackbarMessage,
    snackbarSeverity,
    snackbarOpen,
    handleCloseSnackbar,
    setSnackbarSeverity,
    setSnackbarOpen,
    setSnackbarMessage,
    setError,
    newUserRequest,
    setNewUserRequest,
    newFundRequest,
    setNewFundRequest,
    handleFetchNewUserRequest,
    handleFetchNewFundRequest,
    validationSchema,
    handleSubmit,
  } = useAdminDashboard();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const { data: newUserRequestData = [] } = useQuery(
    "fetch_new_userrequest",
    fetchNewUserRequest
  );

  // Fetch New Fund Requests with useQuery
  const { data: newFundRequestData = [] } = useQuery(
    ["fetch_pending_fundrequest", "pending"],
    () => fetchPendingFundRequest("pending")
  );

  useEffect(() => {
    if (newUserRequestData) {
      setNewUserRequest(newUserRequestData);
    }
    if (newFundRequestData) {
      setNewFundRequest(newFundRequestData);
    }
  }, [
    newUserRequestData,
    newFundRequestData,
    setNewFundRequest,
    setNewUserRequest,
  ]);

  // Handle User Approve/Reject
  const { mutate: approveUser } = useMutation(
    async ({ userId, status }) => {
      const formData = new URLSearchParams();
      formData.append("user_id", userId);
      formData.append("status", status);

      const response = await post("/approved_user", formData);
      return response;
    },
    {
      onSuccess: () => {
        setSnackbarMessage("User action completed successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Refetch both new user requests and pending fund requests
        handleFetchNewUserRequest();
      },
      onError: (error) => {
        setSnackbarMessage("Failed to complete user action.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setError(error);
      },
    }
  );

  // Approve/Reject button handler
  const handleUserAction = (userId, status) => {
    console.log(userId, status);
    approveUser({ userId, status });
  };

  useEffect(() => {
    handleFetchNewUserRequest();
    handleFetchNewFundRequest();
  }, []);
  const pieData = {
    labels: ["Deposit", "Profit"],
    datasets: [
      {
        data: [63, 25], // Default values before API data loads
        backgroundColor: ["#5A8DEE", "#A8D8EA"],
        hoverBackgroundColor: ["#5A8DEE", "#A8D8EA"],
        borderWidth: 1,
      },
    ],
  };

  // Options for Pie Chart (optional)
  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Box>
      <AppHeader breadcrumbs="Dashboard" page="Admin Dashboard" />

      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              padding: 3,
              borderRadius: "15px",
              boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Grid container spacing={2} alignItems="center">
              {/* Left side content */}
              <Grid item xs={12} md={6}>
                <Box>
                  <Button
                    variant="outlined"
                    disabled
                    sx={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#5A8DEE",
                      borderColor: "#E5E5E5",
                      backgroundColor: "#F4F6FD",
                      borderRadius: "10px",
                      marginBottom: "16px",
                    }}
                  >
                    This month
                  </Button>

                  <Typography
                    sx={{
                      fontSize: "30px",
                      fontWeight: "600",
                      color: "#2B3674",
                      fontFamily: "Poppins",
                    }}
                  >
                    ${total_fund_amount}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#A3AED0",
                      fontFamily: "Poppins",
                    }}
                  >
                    Total Spent
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#05CD99",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      marginTop: "8px",
                    }}
                  >
                    On track{" "}
                    <span style={{ color: "#05CD99", marginLeft: "8px" }}>
                      +2.45%
                    </span>
                  </Typography>

                  {/* <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#5A8DEE",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginTop: "16px",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontFamily: "Poppins",
                    }}
                  >
                    View More
                  </Button> */}
                </Box>
              </Grid>

              {/* Right side - Pie Chart */}
              <Grid item xs={12} md={6} sx={{ py: 3 }}>
                <Box sx={{ width: 160, height: 160 }}>
                  <Pie data={pieData} options={pieOptions} />
                </Box>
                <Grid
                  container
                  justifyContent="space-around"
                  sx={{
                    backgroundColor: "#F4F6FD",
                    borderRadius: "10px",
                    padding: "16px",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#5A8DEE",
                        fontWeight: 600,
                      }}
                    >
                      Deposit
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#2B3674",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      63%
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#A8D8EA",
                        fontWeight: 600,
                      }}
                    >
                      Profit
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#2B3674",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      25%
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Bottom section with percentage */}
          </Card>
        </Grid>
        {/* Users Info */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              padding: 3,
              borderRadius: "15px",
              boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography variant="h6">Total Users</Typography>
            <Typography variant="h4">{user_count}</Typography>
          </Card>
        </Grid>

        <Card
          sx={{
            padding: 3,
            margin: 3,
            borderRadius: "15px",
            boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
            backgroundColor: "#FFFFFF",
          }}
          className="w-full"
        >
          <Typography variant="h5">Profile Update</Typography>
          <Grid container spacing={3} alignItems="center" className="my-1">
            {/* Left-aligned DatePicker */}
            <Grid item xs={12} sm={3} container justifyContent="flex-start">
              <DatePicker label="Date" />
            </Grid>

            {/* Centered Amount TextField */}
            <Grid
              item
              xs={12}
              sm={4}
              container
              justifyContent="center"
              alignItems="center"
            >
              <label>Amount</label>
              <TextField placeholder="$1000" />
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              className="flex justify-center items-center"
            >
              <Formik
                initialValues={{
                  rate: "", // initial value for rate
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="flex justify-between w-full items-center">
                    <div className="flex justify-center items-center gap-2">
                      <label>Rate</label>
                      <Field
                        as={TextField}
                        name="rate"
                        placeholder="15%"
                        sx={{
                          "& .MuiInputBase-root": {
                            fontSize: "14px",
                            borderRadius: "30px",
                            border: "none",
                            backgroundColor: "#F4F7FE",
                          },
                          "& .MuiInputAdornment-root": {
                            marginRight: 0,
                          },
                          "& .MuiInputBase-input": {
                            paddingLeft: "20px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "30px",
                          },
                        }}
                        error={touched.rate && Boolean(errors.rate)}
                        helperText={touched.rate && errors.rate}
                      />
                    </div>
                    <Button type="submit">Submit</Button>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Card>

        {/* New User Requests */}
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              padding: 3,
              borderRadius: "15px",
              boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography variant="h5">New User Requests</Typography>
            {newUserRequest.length > 0 ? (
              newUserRequest.map((user) => (
                <Box
                  key={user.userid}
                  className="flex justify-between my-2 items-center"
                >
                  <div className="flex gap-2 items-center">
                    <Avatar
                      src={user.passport_image}
                      sx={{ width: 50, height: 50 }}
                    />
                    <Box>
                      <Typography variant="h6">{user.name}</Typography>
                      <Typography fontWeight="bold">{user.email}</Typography>
                      <Typography>{user.country}</Typography>
                    </Box>
                  </div>
                  <IconButton onClick={(event) => handleMenuOpen(event, user)}>
                    <MoreVert />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography>No new requests</Typography>
            )}
          </Card>
        </Grid>
        {/* New Fund Requests */}
        {/* New Fund Requests */}
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              padding: 3,
              borderRadius: "15px",
              boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography variant="h5">New Fund Requests</Typography>
            {newFundRequest.length > 0 ? (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* Fund requests grid */}
                {newFundRequest.map((fund, index) => (
                  <Grid
                    container
                    key={index}
                    spacing={2}
                    alignItems="center"
                    sx={{ mb: 2 }}
                  >
                    {/* Avatar and User Details */}
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar
                        src="/path-to-avatar.jpg"
                        sx={{ width: 50, height: 50, mr: 2 }}
                      />
                      <Box>
                        <Typography fontWeight="bold">{fund.name}</Typography>
                        <Typography>{fund.date}</Typography>
                      </Box>
                    </Grid>

                    {/* Transaction Details */}
                    <Grid item xs={4}>
                      <Typography
                        fontWeight="bold"
                        color={
                          fund.transaction_type === "Deposit"
                            ? "success.main"
                            : "error.main"
                        }
                      >
                        {fund.transaction_type === "Deposit" ? "+" : "-"} $
                        {fund.amount}
                      </Typography>
                      <Typography fontWeight="bold">
                        {fund.transaction_type}
                      </Typography>
                    </Grid>

                    {/* Action Buttons */}
                    <Grid item xs={4}>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Button
                          variant="contained"
                          onClick={() => handleFundAccept(fund, "Approved")}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => handleFundAccept(fund, "Rejected")}
                        >
                          Reject
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>No fund requests</Typography>
            )}
          </Card>
        </Grid>
      </Grid>
      {/* Snackbar Alert */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => handleUserAction(selectedUser.userid, "Approved")}
        >
          Approve
        </MenuItem>
        <MenuItem
          onClick={() => handleUserAction(selectedUser.userid, "Rejected")}
        >
          Reject
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AdminDashboard;
