import React from "react";
import AppHeader from "../components/Layout/AppHeader";
import HeroFAQs from "../../Home/components/FAQs/FAQs";

const FAQs = () => {
  return (
    <div>
      <AppHeader breakcrumbs="FAQs" page="FAQs" />
      <HeroFAQs fromDashboard={true} className="rounded-2xl" />
    </div>
  );
};

export default FAQs;
