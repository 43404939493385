import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Tabs,
  Tab,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { post } from "utils/axios";
import AppHeader from "./components/Layout/AppHeader";
import { getProfileData } from "utils/auth/getToken";
import AvtarIcon from "../../assets/Dashboard/Avtar.png";

// Validation schemas
const profileValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  mobile: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
});

const securityValidationSchema = Yup.object({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .min(6, "Password should be at least 6 characters long")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

const Profile = () => {
  const [userData, setUserData] = useState(getProfileData());
  const [tabValue, setTabValue] = useState(0);
  const [avatar, setAvatar] = useState(AvtarIcon);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedData = JSON.parse(sessionStorage.getItem("userData"));
      setUserData(updatedData);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setAvatar(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleTabChange = (_, newValue) => setTabValue(newValue);

  const handleUpdateProfile = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await post("/updateuser", {
        token: userData.token,
        ...values,
      });

      const updatedProfile = { ...userData, ...values };
      sessionStorage.setItem("userData", JSON.stringify(updatedProfile));
      setUserData(updatedProfile);
    } catch (error) {
      console.error("Profile update failed", error);
    }
    setSubmitting(false);
  };

  const handleChangePassword = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await post("/changepassword", {
        token: userData.token,
        old_pwd: values.currentPassword,
        new_pwd: values.newPassword,
      });
    } catch (error) {
      console.error("Password change failed", error);
    }
    setSubmitting(false);
  };

  const EditProfile = () => (
    <Formik
      initialValues={{
        name: userData?.name || "",
        referal_code: userData?.referal_code || "",
        email: userData?.email || "",
        mobile: userData?.mobile || "",
        country: userData?.country || "",
      }}
      validationSchema={profileValidationSchema}
      onSubmit={handleUpdateProfile}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={2}>
              <Box
                sx={{ position: "relative" }}
                className="hidden sm:inline-block"
              >
                <Avatar
                  alt="User Avatar"
                  src={avatar}
                  sx={{
                    width: 120,
                    height: 120,
                    mb: 2,
                  }}
                />
                <IconButton
                  color="primary"
                  aria-label="edit profile picture"
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    right: 0,
                    color: "white",
                    backgroundColor: "#2B3674",
                    borderRadius: "50%",
                    padding: 0.5,
                  }}
                  onClick={() => fileInputRef.current.click()}
                >
                  <EditIcon />
                </IconButton>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <ProfileFields isSubmitting={isSubmitting} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  const ProfileFields = ({ isSubmitting }) => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Field
            name="name"
            as={TextField}
            fullWidth
            label="Your Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="referal_code"
            as={TextField}
            fullWidth
            label="Referral Code"
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="email"
            as={TextField}
            fullWidth
            label="Email"
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="mobile"
            as={TextField}
            fullWidth
            label="Mobile Number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="country"
            as={TextField}
            fullWidth
            label="Country"
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Saving..." : "Save"}
      </Button>
    </>
  );

  const Security = () => (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={securityValidationSchema}
      onSubmit={handleChangePassword}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box sx={{ mt: 3, pl: { sm: 4, xs: 0 } }}>
            <PasswordFields isSubmitting={isSubmitting} />
          </Box>
        </Form>
      )}
    </Formik>
  );

  const PasswordFields = ({ isSubmitting }) => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Field
            name="currentPassword"
            as={TextField}
            fullWidth
            label="Current Password"
            type="password"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="newPassword"
            as={TextField}
            fullWidth
            label="New Password"
            type="password"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="confirmPassword"
            as={TextField}
            fullWidth
            label="Confirm New Password"
            type="password"
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Updating Password..." : "Update Password"}
      </Button>
    </>
  );

  return (
    <Box>
      <AppHeader breadcrumbs="Profile" page="Profile" />
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none" }}>
        <CardContent className="hidden sm:block">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Profile Tabs"
          >
            <Tab label="Edit Profile" />
            <Tab label="Security" />
          </Tabs>
          {tabValue === 0 && <EditProfile />}
          {tabValue === 1 && <Security />}
        </CardContent>
      </Card>
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none" }}>
        <CardContent className="block sm:hidden">
          {/* Profile Picture Section */}
          <Box
            sx={{
              position: "relative",
              display: "inline-block",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Avatar
              alt="User Avatar"
              src={avatar}
              sx={{ width: 120, height: 120 }}
            />
            <IconButton
              color="primary"
              aria-label="edit profile picture"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                color: "white",
                backgroundColor: "#2B3674",
                borderRadius: "50%",
                padding: 0.5,
              }}
              onClick={() => fileInputRef.current.click()}
            >
              <EditIcon />
            </IconButton>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </Box>
          <Typography variant="h6" mb={-3}>
            Personal Info
          </Typography>
          {/* Edit Profile Section */}
          <EditProfile />
        </CardContent>
      </Card>
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none" }}>
        <CardContent className="block sm:hidden">
          <Typography variant="h6">Security</Typography>
          {/* Security Section */}
          <Security />
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;
