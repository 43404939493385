import { useCallback, useEffect, useState } from "react";
import { post } from "utils/axios";
import * as Yup from "yup";

const useAdminDashboard = () => {
  const [error, setError] = useState(null);
  const [withdrawalRows, setWithdrawalRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newUserRequest, setNewUserRequest] = useState([]);
  const [newFundRequest, setNewFundRequest] = useState([]);

  // Snackbar states
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Fetch new user and fund requests
  const handleFetchNewUserRequest = async () => {
    const data = await fetchNewUserRequest();
    setNewUserRequest(data);
  };

  const handleFetchNewFundRequest = async () => {
    const data = await fetchPendingFundRequest("pending");
    setNewFundRequest(data);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const use_post_profit_update = async (rate) => {
    const formData = new FormData();
    formData.append("p_rate", rate);
    const response = await post("/profit_update", formData);
    console.log(response);
  };

  // Validation schema
  const validationSchema = Yup.object({
    rate: Yup.string().required("Rate is required"),
  });
  const handleSubmit = async (values) => {
    // Call API with the rate value
    await use_post_profit_update(values.rate);
  };

  const fetchNewUserRequest = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      formData.append("status", "pending");
      const response = await post("/fetch_new_userrequest", formData);

      return response.details || [];
    } catch (error) {
      console.error("Error fetching new user request:", error);
      setError(error.response || error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPendingFundRequest = useCallback(async (type) => {
    setLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      formData.append("transaction_type", "All");
      formData.append("transaction_status", type);

      const response = await post("/transaction_report", formData);

      return response?.transactions || [];
    } catch (error) {
      console.error("Error fetching pending fund requests:", error);
      setError(error.response || error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  // Handle fund request approval or rejection
  const handleFundAccept = useCallback(
    async (fund, type) => {
      setLoading(true);
      setError(null);
      try {
        const formData = new FormData();
        formData.append("txnid", fund.transaction_id);
        formData.append("userid", fund.userid);
        formData.append("walletid", fund.walletid);
        formData.append("transaction_status", type);
        formData.append("description", fund.description);

        await post("/transaction_approved", formData);

        // Refetch the pending fund requests to update the table
        handleFetchNewFundRequest();

        // Show success Snackbar and refetch data
        setSnackbarMessage(`${type} successfully`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        return [];
      } catch (error) {
        setSnackbarMessage(`Failed to ${type}: ${error.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);

        console.error("Error processing fund request:", error);
        setError(error.response || error);
        return [];
      } finally {
        setLoading(false);
      }
    },

    [handleFetchNewFundRequest]
  );

  // Initial data fetching when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      const depositData = await fetchPendingFundRequest("pending");
      const formattedDepositRows = depositData.map((item, index) => ({
        transaction_id: index + 1,
        id: `deposit-${item.id}-${index}`, // Ensure unique ID for deposit
        amount: item.amount,
        user: item.name,
        transaction_type: item.transaction_type,
        date: item.date,
        transaction_status: item.transaction_status,
      }));

      setWithdrawalRows(formattedDepositRows);
      setLoading(false);
    };

    fetchData();
  }, [fetchPendingFundRequest]);

  return {
    fetchNewUserRequest,
    fetchPendingFundRequest,
    withdrawalRows,
    loading,
    error,
    handleFundAccept,
    setLoading,
    snackbarMessage,
    snackbarSeverity,
    snackbarOpen,
    handleCloseSnackbar,
    setSnackbarSeverity,
    setSnackbarOpen,
    setSnackbarMessage,
    setError,
    newUserRequest,
    setNewUserRequest,
    newFundRequest,
    setNewFundRequest,
    handleFetchNewUserRequest,
    handleFetchNewFundRequest,
    validationSchema,
    handleSubmit,
  };
};

export default useAdminDashboard;
