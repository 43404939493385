import React, { useState } from "react";
import Button from "../../../shared/Button";
import Logo from "../../../shared/Logo";
import { NavLink, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton } from "@mui/material";

const navItems = [
  { text: "Home", link: "/" },
  { text: "Dashboard", link: "/dashboard" },
  {
    text: "About Us",
    link: "#", // No navigation, used for scrolling only
    onClick: () => {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },
  {
    text: "Contact Us",
    link: "#", // No navigation, used for scrolling only
    onClick: () => {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },
];

function Header() {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && event.key === "Tab") return;
    setDrawerOpen(open);
  };

  return (
    <header className="flex justify-between items-center w-full px-5 md:px-20 py-4 bg-white md:bg-transparent">
      <div className="md:hidden flex items-center">
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon fontSize="large" />
        </IconButton>
      </div>

      <div className="flex-grow flex justify-center md:justify-start">
        <Logo />
      </div>

      <nav className="hidden md:flex flex-grow justify-center gap-10 text-xl font-medium text-darkblue-700">
        {navItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.link === "#" ? location.pathname : item.link} // Keeps scroll items from navigating
            className={({ isActive }) =>
              `hover:text-brightpink-500 ${
                item.link === "/" && location.pathname === "/"
                  ? "text-brightpink-500"
                  : isActive && item.link !== "#"
                  ? "text-brightpink-500"
                  : ""
              }`
            }
            onClick={(e) => {
              if (item.onClick) {
                e.preventDefault(); // Prevents default navigation for scroll items
                item.onClick();
              }
            }}
          >
            {item.text}
          </NavLink>
        ))}
      </nav>

      <div className="hidden md:flex gap-3 text-base font-semibold">
        <Button variant="outlined" color="secondary">
          <NavLink to="/signup">Sign Up</NavLink>
        </Button>
        <Button variant="contained" color="secondary">
          <NavLink to="/signin">Sign In</NavLink>
        </Button>
      </div>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <div className="flex flex-col gap-5 p-5 w-60 text-xl font-medium text-darkblue-700">
          <IconButton onClick={toggleDrawer(false)} className="self-end">
            <CloseIcon />
          </IconButton>
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.link === "#" ? location.pathname : item.link}
              className={({ isActive }) =>
                `hover:text-brightpink-500 ${
                  item.link === "/" && location.pathname === "/"
                    ? "text-brightpink-500"
                    : isActive && item.link !== "#"
                    ? "text-brightpink-500"
                    : ""
                }`
              }
              onClick={(e) => {
                if (item.onClick) {
                  e.preventDefault();
                  item.onClick();
                } else {
                  toggleDrawer(false)();
                }
              }}
            >
              {item.text}
            </NavLink>
          ))}
        </div>
      </Drawer>
    </header>
  );
}

export default Header;
