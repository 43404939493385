import {
  Box,
  Button,
  Card,
  Grid,
  Snackbar,
  Alert,
  TableContainer,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomField from "components/shared/CustomField/CustomField";
import { DataGrid } from "components/shared";
import useDeposit from "./useDeposit";

// Validation schema using Yup
const validationSchema = Yup.object({
  amount: Yup.number()
    .required("Amount is required")
    .min(1, "Amount should be more than 0"),
  usdtType: Yup.string().required("Please select USDT type"),
  walletAddress: Yup.string().required("Wallet address is required"),
  transactionId: Yup.mixed().required("Transaction ID is required"),
});

function Deposit() {
  const {
    columns,
    rows,
    total_amount,
    handleSubmit,
    handleCloseSnackbar,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
  } = useDeposit();

  return (
    <Box>
      {/* Wallet Deposit */}
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Left side: Wallet Deposit Text */}
          <Grid item sm={6}>
            <Typography
              sx={{
                fontSize: "25px",
                fontFamily: "Poppins",
                color: "#2B3674",
                fontWeight: 600,
              }}
            >
              Wallet Deposit
            </Typography>
          </Grid>

          {/* Right side: Money bag icon and $1000 */}
          <Grid
            item
            sm={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Card
              sx={{
                fontSize: "25px",
                fontFamily: "Poppins",
                color: "black",
                fontWeight: 600,
                borderRadius: 4,
                boxShadow: "none",
                backgroundColor: "#F4F7FE",
                p: 1,
                mb: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {total_amount}
              <FontAwesomeIcon
                icon={faSackDollar}
                style={{
                  marginLeft: "10px",
                  color: "#16DBCC",
                  backgroundColor: "white",
                  padding: 2,
                  borderRadius: 10,
                  width: "30px",
                }}
              />
            </Card>
          </Grid>
        </Grid>
        <hr />

        <Formik
          initialValues={{
            amount: "",
            usdtType: "",
            walletAddress: "",
            transactionId: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <CustomField
                    name="amount"
                    label="Enter Amount: (in USDT)"
                    type="number"
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomField
                    name="usdtType"
                    label="Select Payment Method:"
                    type="select"
                    options={[
                      { value: "USDT TRC20", label: "USDT TRC20" },
                      { value: "USDT ERC20", label: "USDT ERC20" },
                    ]}
                    error={touched.usdtType && Boolean(errors.usdtType)}
                    helperText={touched.usdtType && errors.usdtType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomField
                    name="walletAddress"
                    label="Wallet address: (USDT TRC20)"
                    error={
                      touched.walletAddress && Boolean(errors.walletAddress)
                    }
                    helperText={touched.walletAddress && errors.walletAddress}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label>Upload Transaction ID:</label>
                  <br />
                  <input
                    type="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("transactionId", file);
                    }}
                    style={{
                      borderRadius: "8px",
                      padding: "8px",
                      border: "1px solid #ccc",
                      fontFamily: "Poppins",
                      width: "-webkit-fill-available",
                    }}
                  />
                  <ErrorMessage name="transactionId" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "green",
                      color: "white",
                      fontFamily: "Poppins",
                      mt: 2,
                      borderRadius: 2,
                    }}
                  >
                    Deposit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>

      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <TableContainer
          sx={{
            pl: 1,
            borderRadius: "15px",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows ?? []}
            loading={false}
            tableName="Wallet Deposit History"
          />
        </TableContainer>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Deposit;
