import { Box } from "@mui/material";
import { Button } from "components/shared";
import { useCallback, useEffect, useState } from "react";
import { post } from "utils/axios";
import { useMutation, useQueryClient } from "react-query";

const useAdminProfile = () => {
  const [rows, setRows] = useState([]);
  const [approvedRows, setApprovedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  // Snackbar states
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Query Client to manage refetches
  const queryClient = useQueryClient();

  // Fetch new user requests (for pending users)
  const fetchNewUserRequest = useCallback(async (type) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("status", type);
      const response = await post("/fetch_new_userrequest", formData);
      return response.details || [];
    } catch (error) {
      console.error("Error fetching new user request:", error);
      setSnackbarMessage(error.response || error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch new user and approved requests
  const handleFetchNewUserRequest = async () => {
    const data = await fetchNewUserRequest("pending");
    setRows(data.map((user, index) => ({ id: index + 1, ...user })));
  };

  const handleFetchApprovedUserRequest = async () => {
    const data = await fetchNewUserRequest("Approved");
    setApprovedRows(data.map((user, index) => ({ id: index + 1, ...user })));
  };

  // eslint-disable-next-line
  useEffect(() => {
    handleFetchNewUserRequest();
    handleFetchApprovedUserRequest();
    // eslint-disable-next-line
  }, []);

  const { mutate: approveUser, isLoading: approving } = useMutation(
    async ({ userId, status }) => {
      const formData = new URLSearchParams();
      formData.append("user_id", userId);
      formData.append("status", status);

      const response = await post("/approved_user", formData);
      return { userId, status, response };
    },
    {
      onSuccess: ({ userId, status }) => {
        setSnackbarMessage("User action completed successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        if (status === "Approved") {
          // Move user from pending to approved
          setRows((prevRows) =>
            prevRows.filter((row) => row.userid !== userId)
          );
          setApprovedRows((prevApprovedRows) => [
            ...prevApprovedRows,
            ...rows.filter((row) => row.userid === userId),
          ]);
        } else if (status === "Rejected") {
          // Remove rejected user from pending
          setRows((prevRows) =>
            prevRows.filter((row) => row.userid !== userId)
          );
        }

        // Optionally refetch if backend consistency is needed
        queryClient.invalidateQueries("newUserRequests");
        queryClient.invalidateQueries("approvedUserRequests");
      },
      onError: () => {
        setSnackbarMessage("Failed to complete user action.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      },
    }
  );

  // Approve/Reject button handler
  const handleUserAction = (userId, status) => {
    approveUser({ userId, status });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country / Code",
      flex: 1,
    },
    { field: "referral_id", headerName: "Reference", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box className="flex flex-col md:flex-row justify-between gap-0 m-0 p-0 md:gap-2 md:m-2 md:p-1">
          <Button
            className="!text-white text-[8px] p-0 w-fit md:text-sm md:p-1" // 8px text size with 2px padding
            onClick={() => handleUserAction(params.row.userid, "Approved")} // Approve action
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            className="text-[8px] p-0 w-fit md:text-sm md:p-1" // 8px text size with 2px padding
            onClick={() => handleUserAction(params.row.userid, "Rejected")} // Reject action
          >
            Reject
          </Button>
        </Box>
      ),
    },
  ];

  const approvedColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country / Code",
      flex: 1,
    },
    { field: "referral_id", headerName: "Reference", flex: 1 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Box className="flex justify-center items-center mt-2">
    //       <Button
    //         children={<Visibility className="fill-darkblue-500" />}
    //         className="!text-white"
    //         variant="text"
    //         onClick={() => {
    //           navigate(`details/${params.row.userid}`);
    //         }}
    //       />
    //     </Box>
    //   ),
    // },
  ];

  return {
    rows,
    columns,
    approvedColumns,
    approvedRows,
    loading: loading || approving,
    snackbarMessage,
    snackbarSeverity,
    snackbarOpen,
    handleCloseSnackbar,
  };
};

export default useAdminProfile;
