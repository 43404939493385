import { Card, Typography, Box } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import ChartPattern from "../../../../assets/Dashboard/ChartPattern.svg";
import { useState } from "react";

// Data for each section
const sections = [
  {
    title: "Venture Elevation Club",
    description: `If you refer 10,000 USD in Level 1, you will become a Royalty Achiever
      Royalty: 1% profit generated by WortexAi`,
    examples: [
      "Total Profit Generated by WortexAi is 100,000 USD.",
      "1% of 100,000 is 1,000 USD.",
      "1,000 USD / Total Royalty Achievers (10 Persons).",
      "Each Royalty Achiever will get 100 USD.",
    ],
  },
  {
    title: "Epic Achievers Alliance",
    description: `If you refer 25,000 USD in Level 1 you will become Royalty Achiever
      Royalty: 1% profit generated by WortexAi`,
    examples: [
      "Total Profit Generated by FxTrado is 100,000 USD.",
      "1% of 100,000 is 1,000 USD.",
      "1,000 USD/ Total Royalty Achiever (5 Persons).",
      "Each Royalty Achiever will get 200 USD.",
    ],
  },
  {
    title: "Legacy Leaders Syndicate",
    description: `If you refer 50,000 USD in Level 1 you will become Royalty Achiever
      Royalty: 1% profit generated by WortexAi will be distributed among all achievers`,
    examples: [
      "Total Profit Generated by FxTrado is 100,000 USD.",
      "1% of 100,000 is 1,000 USD.",
      "1,000 USD/ Total Royalty Achiever (2 Persons).",
      "Each Royalty Achiever will get 500 USD.",
    ],
  },
];

const Income = () => {
  const [section, setSection] = useState(1);

  return (
    <Box
      mt={3}
      className="flex flex-col justify-center items-center gap-4 w-full"
    >
      {sections.map((sectionData, idx) =>
        section === idx + 1 ? (
          <Card
            key={idx}
            sx={{
              borderRadius: 4,
              boxShadow: "none",
              backgroundColor: "#FFFFFF",
              backgroundImage: `url(${ChartPattern})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              minHeight: "100%",
            }}
            className="p-16 pb-20"
          >
            <Typography variant="h4" className="font-bold text-darkblue-600">
              {sectionData.title}
            </Typography>

            <Typography variant="body1" fontWeight={500} mt={2} mb={6}>
              {sectionData.description.split("\n").map((line, i) => (
                <span key={i}>
                  {line}
                  <br />
                </span>
              ))}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
                background: "#F4F7FE",
                fontWeight: 500,
              }}
              className="p-10"
            >
              <Typography variant="body1" fontWeight="bold" fontSize="large">
                Example:
              </Typography>
              {sectionData.examples.map((example, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  sx={{ fontWeight: 500 }}
                >
                  <FiberManualRecord className="size-2" /> {example}
                </Typography>
              ))}
            </Box>
          </Card>
        ) : null
      )}

      <div className="bg-white p-2 rounded-2xl w-fit">
        {sections.map((_, idx) => (
          <FiberManualRecord
            key={idx}
            className={`${
              section === idx + 1 ? "fill-brightpink" : "fill-gray-500"
            } cursor-pointer`}
            onClick={() => setSection(idx + 1)}
          />
        ))}
      </div>
    </Box>
  );
};

export default Income;
