import { useState, useEffect, useCallback } from "react";
import { post } from "utils/axios";

const useTransfer = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTransactionData = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("transaction_type", "Withdrawal");
      formData.append("transaction_status", "All");

      const response = await post("/transaction_report", formData);
      const data = response?.transactions || [];

      const formattedRows = data.map((item, index) => ({
        id: index + 1,
        amount: item.amount,
        user: item.walletid,
        transaction_type: item.transaction_type,
        date: item.date,
        transaction_status: item.transaction_status,
      }));

      setRows(formattedRows);
    } catch (err) {
      setError(err.response || err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTransactionData();
  }, [fetchTransactionData]);

  const columns = [
    { field: "id", headerName: "Transaction ID", flex: 1 },
    { field: "user", headerName: "User", flex: 1 },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter: (params) => `$${params}`,
    },
    { field: "transaction_type", headerName: "Transaction Type", flex: 1 },
    { field: "transaction_status", headerName: "Status", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
  ];

  return {
    rows,
    columns,
    loading,
    error,
  };
};

export default useTransfer;
