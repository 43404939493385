import { css } from "@emotion/css";
import { colors } from "style/colors";
import { POPPINS } from "style/constants";

export const noRowsText = css`
  &.MuiTypography-root {
    font-family: ${POPPINS};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
  }
`;

export const dataGridStyle = css`
  &.MuiDataGrid-root {
    font-family: ${POPPINS} !important;
    font-weight: 500;
    background: white;
    border-radius: 6px;
    border: none;
    height: auto;
  }

  & .MuiDataGrid-toolbarContainer {
    padding-bottom: 20px;
  }

  & .MuiDataGrid-toolbarContainer .MuiInputBase-root {
    background-color: #f4f7fe;
    color: #2b3674;
    padding: 10px 10px;
    border-radius: 20px 20px 0 0;
  }

  & .MuiDataGrid-toolbarContainer .MuiSvgIcon-root {
    color: ${colors.darkblue};
  }

  & .MuiDataGrid-columnHeaders {
    height: 52px;
    background-color: #f4f7fe;
  }

  & .MuiDataGrid-columnHeader {
    color: #2b3674;
    font-size: 20px;
    font-weight: bold;
    background-color: #f4f7fe;
    border-right: 1px solid white;
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }

  & .MuiDataGrid-columnHeaderTitle {
    color: #2b3674;
    font-size: 20px;
    font-weight: bold;
  }

  & .MuiDataGrid-columnHeader:focus,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  & .MuiDataGrid-cellContent {
    font-family: ${POPPINS} !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  & .MuiDataGrid-row {
    .MuiDataGrid-cell {
      border-bottom: 1px solid ${colors.tableBorder};
      border-right: 1px solid ${colors.tableBorder};
      border-left: 1px solid ${colors.tableBorder};
    }
  }

  .MuiDataGrid-actionsCell {
    transform: rotate(90deg);
    .MuiButtonBase-root {
      color: ${colors.tableBorder};
    }
  }

  & .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }

  & .MuiDataGrid-filterIcon {
    display: none;
  }

  // Pagination Styles
  & .MuiTablePagination-root {
    color: ${colors.darkblue}; // Color for "Rows per page:"
  }

  & .MuiTablePagination-selectLabel {
    color: #a3aed0; // Color for "Rows per page:" label
  }

  & .MuiTablePagination-displayedRows {
    color: ${colors.darkblue}; // Color for displayed rows text
  }

  & .MuiButtonBase-root {
    color: ${colors.darkblue}; // Default color for pagination buttons
  }

  & .MuiButtonBase-root.Mui-disabled {
    color: #a3aed0; // Color for disabled pagination buttons
  }

  & .MuiTablePagination-actions .MuiIconButton-root {
    color: ${colors.darkblue}; // Active color for pagination action buttons
  }

  & .MuiTablePagination-actions .MuiIconButton-root.Mui-disabled {
    color: #a3aed0; // Disabled color for pagination action buttons
  }

  @media (max-width: 600px) {
    /* Adjust DataGrid for mobile view */
    .MuiDataGrid-columnHeaders {
      height: 20px !important;
    }
    & .MuiDataGrid-columnHeader {
      height: 20px !important;
      font-size: 12px !important;
      padding: 2px !important;
      padding: 0;
    }
    & .MuiDataGrid-columnHeaderTitleContainer {
      padding: 0;
    }
    & .MuiDataGrid-columnHeaderTitle {
      font-size: 10px !important;
      padding: 2px !important;
    }

    .MuiDataGrid-row {
      .MuiDataGrid-cell {
        font-size: 10px !important;
        padding: 2px !important;
      }
    }

    /* Adjust toolbar for mobile */
    .MuiDataGrid-toolbarContainer {
      padding-bottom: 10px; /* Adjust toolbar padding */
    }

    .MuiDataGrid-toolbarContainer .MuiInputBase-root {
      padding: 6px 8px !important; /* Adjust search box padding */
    }
  }
`;
