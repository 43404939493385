import { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Box,
  Drawer,
  CssBaseline,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import AvtarIcon from "../../../../assets/Dashboard/Avtar.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getProfileData } from "utils/auth/getToken";
import AdminSidebar from "./AdminSidebar";

const drawerWidth = 290;

const AppLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const locationPath = location.pathname.split("/");
  const pageTitle = locationPath?.[locationPath.length - 1];
  const navigate = useNavigate();
  const { usertype } = getProfileData();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleLogout = () => {
    sessionStorage.clear(); // Clear session storage
    navigate("/signin", { replace: true }); // Navigate to signin page
    window.location.reload(); // Refresh the page
    setAnchorEl(null); // Close the menu
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); // Close the menu
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgba(188, 215, 251, 0.29)",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />

      {/* AppBar for mobile screens only */}
      <AppBar
        position="fixed"
        sx={{
          display: { xs: "block", md: "none" }, // Show AppBar only on small screens
          width: { xs: "100%" }, // AppBar takes full width on mobile
          backgroundColor: "#fff",
          boxShadow: "none",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Toolbar>
          {/* Hamburger Menu Icon for mobile screens */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ color: "#2B3674" }}
          >
            <MenuIcon />
          </IconButton>

          {/* Page Title */}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", color: "#2B3674" }}
          >
            {pageTitle ?? "Dashboard"}
          </Typography>

          {/* Profile Avatar */}
          <IconButton onClick={handleAvatarClick}>
            <Avatar
              alt="User Avatar"
              src={AvtarIcon}
              sx={{ width: 40, height: 40 }}
            />
          </IconButton>

          {/* Menu for Profile Actions */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Sidebar for larger screens (permanent) */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" }, // Show Sidebar only on larger screens
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {usertype === "admin" ? <AdminSidebar /> : <Sidebar />}
      </Drawer>

      {/* Sidebar for mobile screens (temporary) */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better mobile performance
        }}
        sx={{
          display: { xs: "block", md: "none" }, // Show Sidebar only on mobile when toggled
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
      >
        {usertype === "admin" ? <AdminSidebar /> : <Sidebar />}
      </Drawer>

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` }, // Full width for mobile, calculated for desktop
        }}
      >
        <Toolbar sx={{ display: { xs: "block", md: "none" } }} />{" "}
        {/* Spacer for mobile AppBar */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default AppLayout;
