import { Box, Card, Typography } from "@mui/material";

const Widget = ({ name, image, value }) => {
  return (
    <div>
      <Card
        sx={{
          p: 1,
          borderRadius: 4,
          boxShadow: "none",
          height: "fit-content",
          width: "fit-content",
        }} // Added height to make all cards uniform
      >
        <Box className="flex gap-2 items-center justify-center">
          <Box className="size-8 sm:size-10">
            <img src={image} alt="Deposit" />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "rgba(163, 174, 208, 1)",
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                },
                fontFamily: "Poppins",
              }}
              className="text-nowrap"
            >
              {name}
            </Typography>
            <Typography
              sx={{
                color: "rgba(43, 54, 116, 1)",
                fontWeight: "600",
                fontSize: {
                  xs: "14px",
                  sm: "16px",
                },
                fontFamily: "Poppins",
              }}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default Widget;
