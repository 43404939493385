import React from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Link,
  Container,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom"; // for redirection
import { login } from "utils/signin";
import { styled } from "@mui/material/styles";
import { Logo } from "./shared";
import { useMutation } from "react-query";
import { useAuth } from "utils/auth/AuthProvider";

// Custom TextField styling
const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    fontFamily: "Poppins",
    backgroundColor: "white",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Poppins",
  },
}));

// Render function for the CustomTextField
const renderCustomTextField = (
  formik,
  id,
  name,
  label,
  type = "text",
  otherProps = {}
) => (
  <CustomTextField
    fullWidth
    id={id}
    name={name}
    label={label}
    type={type}
    value={formik.values[name]}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
    {...otherProps}
  />
);

const SignInForm = () => {
  const navigate = useNavigate(); // Hook for navigation

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const { login: loginContext } = useAuth();

  const { mutate: loginMutation, isLoading } = useMutation(login, {
    onSuccess: (data) => {
      loginContext(data); // Update the context with user data
      navigate("/dashboard"); // Navigate to dashboard on successful login
    },
    onError: (error) => {
      console.error("Login failed", error);
    },
  });

  const handleSubmit = (values, { setSubmitting }) => {
    loginMutation(values); // Trigger login mutation
    setSubmitting(false);
  };

  const SignInContent = () => (
    <div className="flex justify-between items-center flex-col sm:flex-row mb-5 sm:mb-0">
      <div>
        <h4 className="text-left font-semibold text-2xl">Login</h4>
        <h5 className="text-left mb-4 text-black text-xl whitespace-nowrap">
          to get started
        </h5>
      </div>
      <div>
        <Logo />
      </div>
    </div>
  );

  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgba(188, 215, 251, 0.29)",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            boxShadow: 3,
            padding: 4,
            mt: 2,
            borderRadius: 4,
            backgroundColor: "#ffffff",
            border: "1px solid transparent",
            fontFamily: "Poppins",
            textAlign: "center",
          }}
        >
          <SignInContent />
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                {/* Email Input */}
                {renderCustomTextField(formik, "email", "email", "Email")}

                {/* Password Input */}
                {renderCustomTextField(
                  formik,
                  "password",
                  "password",
                  "Password",
                  "password"
                )}

                {/* Forgot Password Link */}
                <Link
                  href="#"
                  variant="body2"
                  sx={{
                    display: "block",
                    textAlign: "left",
                    marginBottom: "1rem",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  Forgot Password?
                </Link>

                {/* Submit Button */}
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#FF4560",
                    color: "#fff",
                    padding: "0.75rem",
                  }}
                  disabled={formik.isSubmitting || isLoading}
                >
                  {formik.isSubmitting || isLoading ? "Loading..." : "Continue"}
                </Button>

                {/* Register Link */}
                <Typography
                  variant="body2"
                  sx={{ marginTop: "1rem", textAlign: "center" }}
                >
                  New User? <Link href="/signup">Register</Link>
                </Typography>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Grid>
  );
};

export default SignInForm;
