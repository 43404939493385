import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion,
} from "@mui/material";
import { ReactComponent as ExpandIcon } from "../../assets/Home/SVGs/ExpanIcon.svg";

function Accordion({ id, title, description, ...rest }) {
  return (
    <MuiAccordion
      {...rest}
      className={`border !rounded-xl border-blue w-fit shadow-lg ${
        rest.className || ""
      }`}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon className="size-6" />}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
      >
        <span className="md:text-8 text-4 font-bold">{title}</span>
      </AccordionSummary>
      <AccordionDetails>
        <span className="md:text-6 text-3">{description}</span>
      </AccordionDetails>
    </MuiAccordion>
  );
}

export default Accordion;
