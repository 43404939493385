import { Grid, MenuItem } from "@mui/material";
import { Field } from "formik";
import React from "react";
import TextField from "../TextField";

const CustomField = ({ name, label, type = "text", ...props }) => (
  <Grid item xs={12} md={6}>
    <label>{label}</label>
    <Field
      as={TextField}
      fullWidth
      name={name}
      select={type === "select"} // For dropdown fields
      type={type === "file" ? "text" : type} // For file input
      variant="outlined"
      {...props}
      sx={{
        borderRadius: "8px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
        },
        "& .MuiInputLabel-root": {
          fontFamily: "Poppins",
        },
        "& .MuiInputBase-root": {
          fontFamily: "Poppins",
          color: "blue",
        },
      }}
    >
      {type === "select" &&
        props.options &&
        props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      {type === "file" && (
        <input
          type="file"
          onChange={(event) => {
            const file = event.currentTarget.files[0];
            if (file) {
              props.form.setFieldValue(name, file);
            }
          }}
        />
      )}
    </Field>
  </Grid>
);

export default CustomField;
