import { Routes, Route, Navigate } from "react-router-dom";
import SignUpForm from "../components/SignUpForm";
import Profile from "../components/Dashboard/Profile";
import Dashboard from "../components/Dashboard/Dashboard/Dashboard";
import SignInForm from "../components/SignInForm";
import ProfileHistory from "../components/Dashboard/ProfileHistory";
import AppLayout from "../components/Dashboard/components/Layout/AppLayout";
import Withdraw from "components/Dashboard/Financial-Operation/components/Withdraw/Withdraw";
import Transfer from "components/Dashboard/Financial-Operation/components/Transfer/Transfer";
import FinancialOperation from "components/Dashboard/Financial-Operation/FinancialOperation";
import Deposit from "components/Dashboard/Financial-Operation/components/Deposit";
import FAQs from "components/Dashboard/FAQs/FAQs";
import Transactions from "components/Dashboard/Transactions/Transactions";
import Support from "components/Dashboard/Support/Support";
import Invite from "components/Dashboard/Invite/Invite";
import Link from "components/Dashboard/Invite/components/Link";
import Team from "components/Dashboard/Invite/components/Team";
import Tree from "components/Dashboard/Invite/components/Tree";
import Income from "components/Dashboard/Invite/components/Income";
import { Home } from "components/Home";
import AdminDashboard from "components/Admin/AdminDashboard/Dashboard/AdminDashboard";
import FundRequest from "components/Admin/FundRequest/FundRequest";
import AdminProfile from "components/Admin/AdminProfile/AdminProfile";
import ProfileDetails from "components/Admin/AdminProfile/components/ProfileDetails";
import { useAuth } from "utils/auth/AuthProvider";

export const ProtectedRoute = ({ children, redirectTo = "/signin" }) => {
  const { auth } = useAuth();

  if (!auth.token) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};

const AppRoutes = () => {
  const { auth } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route
        path="/signup"
        element={auth.token ? <Navigate to="/dashboard" /> : <SignUpForm />}
      />
      <Route
        path="/signin"
        element={auth.token ? <Navigate to="/dashboard" /> : <SignInForm />}
      />

      <Route
        path="/request"
        element={
          <ProtectedRoute redirectTo="/signin">
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<FundRequest />} />
      </Route>

      <Route
        path="/users"
        element={
          <ProtectedRoute redirectTo="/signin">
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<AdminProfile />} />
        <Route path="details/:id" element={<ProfileDetails isAdmin={true} />} />
      </Route>

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute redirectTo="/signin">
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            auth.userType === "admin" ? <AdminDashboard /> : <Dashboard />
          }
        />
        <Route path="profile" element={<Profile />} />
        <Route path="your-profit-history" element={<ProfileHistory />} />

        <Route path="financial-operation" element={<FinancialOperation />}>
          <Route index path="deposit" element={<Deposit />} />
          <Route path="withdraw" element={<Withdraw />} />
          <Route path="transfer" element={<Transfer />} />
        </Route>
        <Route path="transactions" element={<Transactions />} />
        <Route path="support" element={<Support />} />
        <Route path="invite" element={<Invite />}>
          <Route path="link" element={<Link />} />
          <Route path="team" element={<Team />} />
          <Route path="tree" element={<Tree />} />
          <Route path="income" element={<Income />} />
        </Route>
        <Route path="faqs" element={<FAQs />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
