import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as SavingIcon } from "../../../../assets/Dashboard/Sidebar/Savings.svg";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { Home, ExpandLess, ExpandMore, Person } from "@mui/icons-material";
import "../../../../style/Style.css";
import Logo from "../../../shared/Logo";

const AdminSidebar = () => {
  const location = useLocation();

  const [financialOpen, setFinancialOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);

  const handleFinancialClick = () => {
    setFinancialOpen(!financialOpen);
  };

  const handleInviteClick = () => {
    setInviteOpen(!inviteOpen);
  };

  const menuItems = [
    { text: "Dashboard", icon: <Home />, path: "/dashboard" },
    { text: "Fund Request", icon: <SavingIcon />, path: "/request" },
    {
      text: "Users",
      icon: <Person />,
      path: "/users",
    },
  ];

  const isActive = (path) => location.pathname === path;
  const isChildActive = (childPaths) =>
    childPaths.some((childPath) => location.pathname === childPath);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 290,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 290,
          boxSizing: "border-box",
          overflowY: "auto",
        },
      }}
      className="custom-scrollbar"
    >
      <Box className="pt-2 flex justify-center items-center">
        <Logo />
        <Box sx={{ borderBottom: "1px solid #ddd", my: 2 }} />
      </Box>
      <List>
        {menuItems.map((item) => (
          <div key={item.text}>
            {/* Parent Item */}
            <ListItem
              component={Link}
              to={item.path}
              sx={{
                backgroundColor:
                  isActive(item.path) && !item.hasChildren
                    ? "#e3f2fd"
                    : "transparent",
                color:
                  isActive(item.path) || isChildActive(item.childPaths || [])
                    ? "#2B3674"
                    : "#A3AED0",
                fontFamily: "Poppins",
              }}
              onClick={
                item.hasChildren
                  ? item.text === "Financial Operation"
                    ? handleFinancialClick
                    : handleInviteClick
                  : undefined
              }
            >
              <ListItemIcon
                sx={{
                  color:
                    isActive(item.path) || isChildActive(item.childPaths || [])
                      ? "#2B3674"
                      : "inherit",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  "& .MuiListItemText-primary": {
                    color:
                      isActive(item.path) ||
                      isChildActive(item.childPaths || [])
                        ? "#2B3674"
                        : "inherit",
                    fontWeight:
                      isActive(item.path) ||
                      isChildActive(item.childPaths || [])
                        ? "bold"
                        : "normal",
                  },
                }}
              />
              {item.hasChildren &&
                (item.text === "Financial Operation" ? (
                  financialOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : inviteOpen ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                ))}
            </ListItem>
          </div>
        ))}
      </List>
    </Drawer>
  );
};

export default AdminSidebar;
