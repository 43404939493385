import { useState, useEffect, useCallback } from "react";
import { getProfileData } from "utils/auth/getToken";
import { post } from "utils/axios";

const useSupport = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Fetch support tickets from the API
  const fetchSupportData = useCallback(async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("status", "All");

      const response = await post("/support_report", formData);
      const data = response.data || [];

      const formattedRows = data.map((item) => ({
        id: item.supportid,
        user_id: item.user_id,
        subject: item.subject,
        description: item.description,
        status: item.status,
        date_created: item.date_created,
        action: item.action,
        attachment: item.attachment,
      }));

      setRows(formattedRows);
    } catch (err) {
      setError(err.response || err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSupportData();
  }, [fetchSupportData]);

  // Submit new support request API
  const submitSupportRequest = async (formData) => {
    const response = await post("/support_request", formData);
    return response;
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("subject", values.subject);
    formData.append("description", values.description);
    if (values.support_file) {
      formData.append("support_file", values.support_file);
    }

    try {
      // Make the API call to submit a support request
      const response = await submitSupportRequest(formData);

      if (response.message) {
        // Show a success message in the snackbar
        setSnackbarMessage(response.message);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Reset the form and refresh the support data
        resetForm();
        await fetchSupportData(); // Refetch rows after successful submission
      } else {
        throw new Error("Unexpected response structure");
      }
    } catch (error) {
      console.error("Error submitting support request:", error);
      setSnackbarMessage("Failed to submit support request.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const { total_amount } = getProfileData();
  return {
    rows,
    columns: [
      { field: "id", headerName: "Support ID", flex: 1 },
      { field: "user_id", headerName: "User ID", flex: 1 },
      { field: "subject", headerName: "Subject", flex: 1 },
      { field: "description", headerName: "Description", flex: 2 },
      { field: "status", headerName: "Status", flex: 1 },
      { field: "date_created", headerName: "Date Created", flex: 1 },
      { field: "action", headerName: "Action", flex: 1 },
      // {
      //   field: "attachment",
      //   headerName: "Attachment",
      //   flex: 1,
      //   renderCell: (params) =>
      //     params.value ? (
      //       <a
      //         href={
      //           params.value.startsWith("http")
      //             ? params.value
      //             : `https://${params.value}`
      //         }
      //         target="_blank"
      //         rel="noopener noreferrer"
      //       >
      //         View
      //       </a>
      //     ) : (
      //       "No Attachment"
      //     ),
      // },
    ],
    loading,
    error,
    handleSubmit,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setSnackbarOpen,
    total_amount,
  };
};

export default useSupport;
