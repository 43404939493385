import {
  Box,
  Button,
  Card,
  Grid,
  Snackbar,
  Alert,
  TableContainer,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomField from "components/shared/CustomField/CustomField";
import { DataGrid } from "components/shared";
import useWithdraw from "./useWithdraw";

// Validation schema using Yup
const validationSchema = Yup.object({
  amount: Yup.number()
    .required("Amount is required")
    .min(1, "Amount should be more than 0"),
  usdtType: Yup.string().required("Please select USDT type"),
  walletAddress: Yup.string().required("Wallet address is required"),
  comment: Yup.string().notRequired(),
});

function Withdraw() {
  const {
    columns,
    rows,
    total_amount,
    handleCloseSnackbar,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    handleSubmit,
  } = useWithdraw();

  return (
    <Box>
      {/* Wallet Withdraw */}
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Left side: Wallet Withdraw Text */}
          <Grid item md={6}>
            <Typography
              sx={{
                fontSize: "25px",
                fontFamily: "Poppins",
                color: "#2B3674",
                fontWeight: 600,
              }}
            >
              Wallet Withdraw
            </Typography>
          </Grid>

          {/* Right side: Money bag icon and $1000 */}
          <Grid
            item
            md={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Card
              sx={{
                fontSize: "25px",
                fontFamily: "Poppins",
                color: "black",
                fontWeight: 600,
                borderRadius: 4,
                boxShadow: "none",
                backgroundColor: "#F4F7FE",
                p: 1,
                mb: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {total_amount}
              <FontAwesomeIcon
                icon={faSackDollar}
                style={{
                  marginLeft: "10px",
                  color: "#16DBCC",
                  backgroundColor: "white",
                  padding: 2,
                  borderRadius: 10,
                  width: "30px",
                }}
              />
            </Card>
          </Grid>
        </Grid>
        <hr />

        <Grid container>
          <Formik
            initialValues={{
              amount: "",
              usdtType: "",
              walletAddress: "",
              comment: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {/* Enter Amount Field */}
                  <CustomField
                    name="amount"
                    label="Enter Amount: (in USDT)"
                    type="number"
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />

                  {/* USDT Type Dropdown */}
                  <CustomField
                    name="usdtType"
                    label="Select Payment Method:"
                    type="select"
                    options={[
                      { value: "USDT TRC20", label: "USDT TRC20" },
                      { value: "USDT ERC20", label: "USDT ERC20" },
                    ]}
                    error={touched.usdtType && Boolean(errors.usdtType)}
                    helperText={touched.usdtType && errors.usdtType}
                  />

                  {/* Wallet Address Field */}
                  <CustomField
                    name="walletAddress"
                    label="Wallet address: (USDT TRC20)"
                    error={
                      touched.walletAddress && Boolean(errors.walletAddress)
                    }
                    helperText={touched.walletAddress && errors.walletAddress}
                  />

                  {/* Comment Field */}
                  <CustomField
                    name="comment"
                    label="Comment"
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={touched.comment && errors.comment}
                  />

                  {/* Submit Button */}
                  <Grid item xs={12} md={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "green",
                        color: "white",
                        fontFamily: "Poppins",
                        mt: 2,
                        borderRadius: 2,
                      }}
                    >
                      Withdraw
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Card>

      {/* Withdraw Transaction History */}
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <TableContainer
          sx={{
            pl: 1,
            borderRadius: "15px",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows ?? []}
            loading={false}
            tableName="Wallet Withdraw History"
          />
        </TableContainer>
      </Card>

      {/* Snackbar for Alerts */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Withdraw;
