import { Typography } from "@mui/material";
import React from "react";
import Accordion from "../../../shared/Accordion";
const faqs = [
  {
    title: "Risk Management Solutions!",
    description:
      "Wortex AI is an advanced AI-driven platform specializing in Forex trading solutions. It leverages cutting-edge technology to analyze market trends, execute trades, and optimize trading strategies for maximum profitability.",
  },
  {
    title: "How does Wortex AI work?",
    description:
      "Wortex AI is an advanced AI-driven platform specializing in Forex trading solutions. It leverages cutting-edge technology to analyze market trends, execute trades, and optimize trading strategies for maximum profitability.",
  },
  {
    title: "What sets Wortex AI apart from other trading platforms?",
    description:
      "Wortex AI is an advanced AI-driven platform specializing in Forex trading solutions. It leverages cutting-edge technology to analyze market trends, execute trades, and optimize trading strategies for maximum profitability.",
  },
  {
    title: "Is Wortex AI suitable for beginners?",
    description:
      "Wortex AI is an advanced AI-driven platform specializing in Forex trading solutions. It leverages cutting-edge technology to analyze market trends, execute trades, and optimize trading strategies for maximum profitability.",
  },
  {
    title: "How secure is Wortex AI?",
    description:
      "Wortex AI is an advanced AI-driven platform specializing in Forex trading solutions. It leverages cutting-edge technology to analyze market trends, execute trades, and optimize trading strategies for maximum profitability.",
  },
];
const FAQs = ({ ...props }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center w-full ${
        props.fromDashboard ? "" : "-mt-[10%]"
      } px-20 pb-10 bg-white`}
      {...props}
    >
      {!props.fromDashboard && (
        <div className="text-brightpink font-bold py-3 px-14 border-2 border-brightpink bg-brightpink-50 rounded-3xl w-fit justify-center flex items-center">
          FAQ&apos;s
        </div>
      )}
      <Typography
        variant="h4"
        className="font-bold text-darkblue max-w-xl mt-6 text-8 md:text-12 text-center"
      >
        Frequently Asked Questions
      </Typography>
      <div className="py-8 flex flex-col max-w-4xl gap-4">
        {faqs.map(({ title, description }, index) => (
          <Accordion
            id={index}
            title={title}
            description={description}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQs;
