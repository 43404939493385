import { Grid, Typography } from "@mui/material";
import { ReactComponent as AIIcon } from "../../../../assets/Home/SVGs/AIIcon.svg";
import { ReactComponent as SavingIcon } from "../../../../assets/Home/SVGs/SavingIcon.svg";
import { ReactComponent as CustomerIcon } from "../../../../assets/Home/SVGs/CustomerIcon.svg";
import { ReactComponent as ShieldIcon } from "../../../../assets/Home/SVGs/ShieldIcon.svg";
const services = [
  {
    icon: <AIIcon className="size-12 md:size-20" />,
    title: "AI Powered Analysis!",
    description:
      "Experience unparalleled insights with Wortex AI's AI-powered analysis in Forex trading. Harness the potential of advanced algorithms to make informed decisions and outsmart the market with precision.",
  },
  {
    icon: <SavingIcon className="size-12 md:size-20" />,
    title: "Time Efficiency!",
    description:
      "We optimize time efficiency in Forex trading, leveraging automation and AI to execute trades swiftly. Stay ahead of the curve and seize opportunities faster, maximizing your returns with minimal effort.",
  },
  {
    icon: <CustomerIcon className="size-12 md:size-20" />,
    title: "Automated Execution!",
    description:
      "With Wortex AI's automated execution, streamline your Forex trading process and capitalize on market opportunities instantly. Let our cutting-edge technology execute trades with precision and efficiency, freeing you to focus on strategic decision-making.",
  },
  {
    icon: <ShieldIcon className="size-12 md:size-20" />,
    title: "Security Measures!",
    description:
      "Safeguard your investments with Wortex AI's robust security measures, including encryption and multi-factor authentication. Rest assured knowing your funds and personal information are protected, allowing you to trade with peace of mind.",
  },
];
const Services = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full py-20">
      <div className="text-brightpink font-bold py-3 px-8 border-2 border-brightpink bg-brightpink-50 rounded-3xl w-fit justify-center flex items-center">
        Our Services
      </div>
      <Typography
        variant="h4"
        className="font-bold text-darkblue max-w-xl mt-6 text-8 md:text-12 text-center"
      >
        Features We Have
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        className="flex justify-center items-center w-full 50 mt-6 gap-6"
      >
        {services.map(({ icon, title, description }, index) => (
          <Grid
            key={index}
            item
            xs={12} // Full width on mobile
            sm={6} // 2 items per row on small screens (tablet)
            md={5} // 5 columns on desktop
            className="w-full flex flex-col shadow-lg gap-2 !p-8 bg-white rounded-lg"
          >
            {icon}
            <Typography variant="body1" className="font-bold">
              {title}
            </Typography>
            <Typography variant="description" className="max-w-md">
              {description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Services;
