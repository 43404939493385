import React from "react";
import AppHeader from "../components/Layout/AppHeader";
import { Outlet } from "react-router-dom";

const FinancialOperation = () => {
  return (
    <div>
      <AppHeader breadcrumbs="Financial Operation" page="Financial Operation" />
      <Outlet />
    </div>
  );
};

export default FinancialOperation;
