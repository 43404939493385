import { TextField as MuiTextField } from "@mui/material";
import React from "react";

const TextField = React.forwardRef((props, ref) => (
  <MuiTextField
    sx={{
      ml: 1, // adds spacing between the label and TextField
      "& .MuiInputBase-root": {
        fontSize: "14px",
        borderRadius: "30px",
        border: "none",
        backgroundColor: "#F4F7FE",
      },
      "& .MuiInputAdornment-root": {
        marginRight: 0,
      },
      "& .MuiInputBase-input": {
        paddingLeft: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "30px",
      },
      ...props.sx, // override default styles with props.sx if provided
    }}
    {...props}
    ref={ref}
  />
));

// Set a display name for the component
TextField.displayName = "TextField";

export default TextField;
