export const getToken = () => sessionStorage.getItem("token");
export const getProfileData = () => {
  return {
    token: sessionStorage.getItem("token"),
    total_amount: sessionStorage.getItem("total_amount"),
    total_deposit: sessionStorage.getItem("total_deposit"),
    total_earning: sessionStorage.getItem("total_earning"),
    total_referral_earning: sessionStorage.getItem("total_referral_earning"),
    total_withdrawn: sessionStorage.getItem("total_withdrawn"),
    usertype: sessionStorage.getItem("usertype"),
    total_fund_amount: sessionStorage.getItem("total_fund_amount"),
    name: sessionStorage.getItem("name"),
    email: sessionStorage.getItem("email"),
    mobile: sessionStorage.getItem("mobile"),
    country: sessionStorage.getItem("country"),
    passport_number: sessionStorage.getItem("passport_number"),
    referal_code: sessionStorage.getItem("referal_code"),
    user_count: sessionStorage.getItem("user_count") ?? 0,
  };
};
