import { useState } from "react";
import IncomeAreaChart from "./IncomeAreaChart";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function UniqueVisitorCard() {
  const [slot] = useState("month");

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Monthly Revenue</Typography>
        </Grid>
      </Grid>
      <Box sx={{ pt: 1, pr: 2 }}>
        <IncomeAreaChart slot={slot} />
      </Box>
    </>
  );
}
