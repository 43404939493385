import { useState, useEffect, useCallback } from "react";
import { post } from "utils/axios";

const useTransactions = () => {
  const [depositRows, setDepositRows] = useState([]);
  const [withdrawalRows, setWithdrawalRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTransactionData = useCallback(async (type) => {
    try {
      const formData = new FormData();
      formData.append("transaction_type", type);
      formData.append("transaction_status", "All");

      const response = await post("/transaction_report", formData);
      return response?.transactions || [];
    } catch (err) {
      setError(err.response || err);
      return [];
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Reset error state before fetching

      const depositData = await fetchTransactionData("Deposit");
      const withdrawalData = await fetchTransactionData("Withdrawal");

      const formattedDepositRows = depositData.map((item, index) => ({
        transaction_id: index + 1,
        id: `deposit-${item.id}-${index}`, // Ensure unique ID for deposit
        amount: item.amount,
        user: item.walletid,
        transaction_type: item.transaction_type,
        date: item.date,
        transaction_status: item.transaction_status,
      }));

      const formattedWithdrawalRows = withdrawalData.map((item, index) => ({
        transaction_id: index + 1,
        id: `withdrawal-${item.id}-${index}`, // Ensure unique ID for withdrawal
        amount: item.amount,
        user: item.walletid,
        transaction_type: item.transaction_type,
        date: item.date,
        transaction_status: item.transaction_status,
      }));

      setDepositRows(formattedDepositRows);
      setWithdrawalRows(formattedWithdrawalRows);
      setLoading(false);
    };

    fetchData();
  }, [fetchTransactionData]);

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      flex: 1,
    },
    { field: "user", headerName: "User", flex: 1 },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter: (params) => `$${params}`, // Access amount correctly
    },
    { field: "transaction_type", headerName: "Transaction Type", flex: 1 },
    { field: "transaction_status", headerName: "Status", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
  ];

  return {
    depositRows,
    withdrawalRows,
    columns,
    loading,
    error,
  };
};

export default useTransactions;
