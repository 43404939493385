import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import { useMutation } from "react-query";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "./shared";

// Validation Schema
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Must be a valid 10-digit mobile number")
    .required("Mobile number is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  country: Yup.string().required("Country is required"),
  referralCode: Yup.string(),
  passport_number: Yup.string().required("Passport Number is required"),
  image: Yup.mixed().required("Passport image is required"),
  agreeToTerms: Yup.bool().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

// Custom TextField component
const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(),
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    fontFamily: "Poppins",
    backgroundColor: "white",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Poppins",
  },
}));

// API function to call the signup endpoint
const signup = async (formData) => {
  const formDataToSend = new FormData();
  formDataToSend.append("name", formData.name);
  formDataToSend.append("email", formData.email);
  formDataToSend.append("mobile", formData.mobile);
  formDataToSend.append("password", formData.password);
  formDataToSend.append("country", formData.country);
  formDataToSend.append("referralCode", formData.referralCode);
  formDataToSend.append("passport_number", formData.passport_number);
  formDataToSend.append("passport_image", formData.image); // Adding the binary image

  const response = await axios.post(
    "https://fx-back.vercel.app/v1/signup",
    formDataToSend
  );
  return response.data;
};

// Function to generate the CustomTextField
const renderCustomTextField = (
  formik,
  id,
  name,
  label,
  type = "text",
  otherProps = {}
) => (
  <CustomTextField
    fullWidth
    id={id}
    name={name}
    label={label}
    type={type}
    value={formik.values[name]}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
    {...otherProps}
  />
);

const SignUpForm = () => {
  // Using useMutation from react-query to handle the API call
  const { mutate, isLoading, isError, isSuccess } = useMutation(signup);
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      navigate("/signin");
    }
  }, [isSuccess, navigate]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      country: "",
      referralCode: "",
      passport_number: "",
      image: null,
      agreeToTerms: false,
    },
    validationSchema,
    onSubmit: (values) => {
      mutate(values); // Call the API using react-query's mutate
    },
  });

  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgba(188, 215, 251, 0.29)",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            boxShadow: 3,
            padding: 4,
            mt: 2,
            borderRadius: 4,
            backgroundColor: "#ffffff",
            border: "1px solid rgba(5, 86, 194, 1)",
            fontFamily: "Poppins",
          }}
        >
          <Grid container justifyContent="space-between" pb={2}>
            <Grid item md={6}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>
                SignUp
              </Typography>
            </Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>
              <Logo />
            </Grid>
          </Grid>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {renderCustomTextField(formik, "name", "name", "Name")}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderCustomTextField(formik, "email", "email", "Email")}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderCustomTextField(
                  formik,
                  "mobile",
                  "mobile",
                  "Mobile Number"
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderCustomTextField(formik, "country", "country", "Country")}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderCustomTextField(
                  formik,
                  "password",
                  "password",
                  "Password",
                  "password"
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderCustomTextField(
                  formik,
                  "confirmPassword",
                  "confirmPassword",
                  "Confirm Password",
                  "password"
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Upload Passport Image:</label>
                <br />
                <input
                  type="file"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    if (file) {
                      formik.setFieldValue("image", file);
                    }
                  }}
                  style={{
                    borderRadius: "8px",
                    padding: "8px",
                    border: "1px solid #ccc",
                    fontFamily: "Poppins",
                  }}
                />
                {formik.touched.image && formik.errors.image ? (
                  <Typography color="error" sx={{ fontFamily: "Poppins" }}>
                    {formik.errors.image}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderCustomTextField(
                  formik,
                  "passport_number",
                  "passport_number",
                  "Passport Number"
                )}
              </Grid>

              <Grid item xs={12}>
                {renderCustomTextField(
                  formik,
                  "referralCode",
                  "referralCode",
                  "Referral Code (Optional)"
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                container
                justifyContent="left"
                alignItems="center"
                textAlign="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="agreeToTerms"
                      name="agreeToTerms"
                      checked={formik.values.agreeToTerms}
                      onChange={formik.handleChange}
                      sx={{
                        fontFamily: "Poppins",
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                      }}
                    >
                      Agree to Our{" "}
                      <a
                        href="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#0556C2", textDecoration: "none" }}
                      >
                        terms and conditions
                      </a>
                    </Typography>
                  }
                />
                {formik.touched.agreeToTerms && formik.errors.agreeToTerms && (
                  <Typography color="error" sx={{ fontFamily: "Poppins" }}>
                    {formik.errors.agreeToTerms}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                mb: 2,
                fontWeight: 600,
                textTransform: "none",
                backgroundColor: "#ff5252",
                width: "50%",
                ":hover": { backgroundColor: "#ff7979" },
              }}
              disabled={isLoading}
            >
              {isLoading ? "Signing Up..." : "Continue"}
            </Button>

            {isError && (
              <Typography color="error" sx={{ fontFamily: "Poppins" }}>
                {"Something went wrong!"}
              </Typography>
            )}

            {isSuccess && (
              <Typography color="success" sx={{ fontFamily: "Poppins" }}>
                Signup successful! Please check your email to confirm.
              </Typography>
            )}
          </form>

          <Typography
            variant="body2"
            sx={{
              fontFamily: "Poppins",
            }}
          >
            Already registered?{" "}
            <a href="/signin" style={{ color: "#396A62" }}>
              Login
            </a>
          </Typography>
        </Box>
      </Container>
    </Grid>
  );
};

export default SignUpForm;
