import { Typography } from "@mui/material";
import {
  DataGrid as MuiDataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useState } from "react";
import dayjs from "dayjs"; // Importing dayjs for date handling
import { dataGridStyle } from "./datagrid.style";

export const convertHexaToRgba = (hex, alpha) => {
  const r = parseInt(hex?.slice(1, 3), 16);
  const g = parseInt(hex?.slice(3, 5), 16);
  const b = parseInt(hex?.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};
const CustomToolbar = ({
  tableName,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  return (
    <GridToolbarContainer className="flex justify-between">
      {tableName && (
        <Typography
          variant="h6"
          component="div"
          style={{ padding: "10px 0" }}
          sx={{
            fontSize: "22px",
            fontFamily: "Poppins",
            color: "#2B3674",
            fontWeight: 600,
          }}
        >
          {tableName}
        </Typography>
      )}

      <div style={{ display: "flex", alignItems: "center" }}>
        {fromDate && toDate && (
          <>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => {
                console.log({ e });
                return setFromDate(e.target.value);
              }}
              style={{
                marginRight: "10px",
                padding: "20px",
                background: "#F4F7FE",
                borderRadius: "8px",
              }}
            />
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              style={{
                marginRight: "10px",
                padding: "20px",
                background: "#F4F7FE",
                borderRadius: "8px",
              }}
            />
          </>
        )}
        {tableName && (
          <GridToolbarQuickFilter
            style={{
              backgroundColor: "#F4F7FE",
              padding: "6px 10px",
              borderRadius: "8px 8px 0 0",
              fontSize: "20px",
            }}
          />
        )}
      </div>
    </GridToolbarContainer>
  );
};

const DataGrid = ({ loading, columns, rows, ...rest }) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const [fromDate, setFromDate] = useState(rest.fromDate);
  const [toDate, setToDate] = useState(rest.toDate);

  // Filter rows based on date
  const filteredRows = rows.filter((row) => {
    const rowDate = dayjs(row.date); // Assuming row.date is in a format dayjs can parse
    const startDate = dayjs(fromDate);
    const endDate = dayjs(toDate);

    return (
      (!fromDate || rowDate.isAfter(startDate.subtract(1, "day"))) &&
      (!toDate || rowDate.isBefore(endDate.add(1, "day")))
    );
  });

  return (
    <MuiDataGrid
      sx={{
        boxShadow: "none",
        ...(loading && { minHeight: "230px !important" }),
        ...rest.sx,
      }}
      className={dataGridStyle}
      autoHeight
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableColumnMenu
      hideFooterSelectedRowCount
      columns={columns}
      rows={filteredRows} // Use filtered rows for rendering
      pagination
      paginationMode="client"
      rowCount={filteredRows.length}
      pageSizeOptions={[5, 10, 15]}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      loading={loading}
      slots={{
        toolbar: () => (
          <CustomToolbar
            tableName={rest.tableName}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
          />
        ),
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
        },
      }}
      {...rest}
    />
  );
};

export default DataGrid;
