import { Typography } from "@mui/material";
import React from "react";
import { ReactComponent as MeetingIcon } from "../../../../assets/Home/SVGs/MeetingIcon.svg";
import { ReactComponent as ExperienceIcon } from "../../../../assets/Home/SVGs/ExperienceIcon.svg";
import { ReactComponent as EconomicIcon } from "../../../../assets/Home/SVGs/EconomicIcon.svg";
import { ReactComponent as KnowMoreImage } from "../../../../assets/Home/SVGs/KnowMoreImage.svg";
import Button from "../../../shared/Button";
import { ArrowRightAltOutlined } from "@mui/icons-material";

const experiences = [
  {
    icon: <ExperienceIcon className="w-12 h-12 md:w-auto md:h-auto" />, // Use responsive sizing classes
    line1: "2x",
    line2: "Growth Rate",
  },
  {
    icon: <EconomicIcon className="w-12 h-12 md:w-auto md:h-auto" />,
    line1: "5+ Years in",
    line2: "Industry",
  },
  {
    icon: <MeetingIcon className="w-12 h-12 md:w-auto md:h-auto" />,
    line1: "652+ Expert",
    line2: "Professionals",
  },
];

const Knowmore = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center px-5 md:px-20">
      <div className="flex flex-col md:flex-row w-full justify-center items-center">
        {/* Single rendering for KnowMoreImage */}
        <div className="w-full flex justify-center">
          <KnowMoreImage className="w-[294px] md:w-auto" />{" "}
          {/* Set width responsively */}
        </div>
        <div className="flex flex-col justify-between w-full gap-6">
          <Typography
            variant="h4"
            className="font-bold text-darkblue-600 max-w-sm text-[32px] md:text-4xl"
          >
            Explore a safer approach for trading
          </Typography>
          <Typography variant="body1" className="text-sm md:text-lg max-w-lg">
            Learn how to use Wortex AI&apos;s AI technology to trade more
            profitably. Use artificial intelligence to your advantage to execute
            trades with accuracy, assess market trends, and fine-tune your
            trading strategy for optimal returns. You just need to deposit and
            start trading with our automated trading system.
          </Typography>
          <Button variant="contained" color="secondary">
            Know More &nbsp; <ArrowRightAltOutlined />
          </Button>
        </div>
      </div>
      <div className="w-full">
        <Typography
          variant="h4"
          className="font-bold text-darkblue-600 pt-8 text-center justify-center text-sm md:text-2xl"
        >
          Over
          <span className="text-brightpink">528+ Satisfied Clients</span> Have
          Chosen Us as Their Trusted Partner
        </Typography>
        <div className="flex flex-col gap-4 md:gap-0 md:flex-row justify-around items-center bg-white shadow-lg rounded-2xl py-4 my-8 w-full">
          {experiences.map(({ icon, line1, line2 }, index) => (
            <div className="flex gap-3 justify-center items-center" key={index}>
              {/* Icon size adjusted for mobile */}
              <div>{icon}</div>
              <div className="flex flex-col font-bold text-sm md:text-base">
                <div>{line1}</div>
                <div>{line2}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Knowmore;
