import { Box } from "@mui/material";
import AppHeader from "../components/Layout/AppHeader";
import { Outlet } from "react-router-dom";

const Invite = () => {
  return (
    <Box>
      <AppHeader breadcrumbs="Invite & Earn" page="Invite & Earn" />
      <Outlet />
    </Box>
  );
};

export default Invite;
