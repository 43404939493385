import { Typography } from "@mui/material";
import { ReactComponent as TickIcon } from "../../../../assets/Home/SVGs/TickIcon.svg";
import Button from "../../../shared/Button";
import { ArrowRightAltOutlined } from "@mui/icons-material";

const Explore = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center px-20 py-16 bg-darkblue-50">
      <div className="flex flex-col md:flex-row w-full justify-center items-center">
        <div className="flex flex-col justify-between w-full gap-6">
          <Typography
            variant="h4"
            className="font-bold text-darkblue-600 max-w-xl"
          >
            Our dedication to assisting you in expanding your company!
          </Typography>

          {[
            "Customized tactics for your particular company's need.",
            "A committed support staff is on hand to provide individualized help.",
            "Constant optimization to optimize possibilities for growth.",
            "Cutting-edge technology to maintain an advantage in cutthroat marketplaces.",
          ].map((offering, index) => (
            <div key={index} className="flex gap-6">
              <TickIcon className="size-6" />
              <Typography variant="body1" className="md:text-lg text-sm">
                {offering}
              </Typography>
            </div>
          ))}
          <Button variant="contained" color="secondary">
            Our Explore &nbsp; <ArrowRightAltOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Explore;
