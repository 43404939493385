import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { Typography } from "@mui/material";

const DatePicker = ({ label, ...rest }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div className="flex gap-2 justify-center items-center">
      <Typography variant="body1" fontWeight="bold">
        {label}
      </Typography>
      <MuiDatePicker
        slots={{ textField: TextField }} // Use slots to specify the custom TextField component
        slotProps={{
          textField: {
            sx: {
              "& .MuiInputBase-root": {
                fontSize: "14px",
                borderRadius: "30px",
                border: "none",
                backgroundColor: "#F4F7FE",
              },
              "& .MuiInputAdornment-root": {
                marginRight: 0,
              },
              "& .MuiInputBase-input": {
                paddingLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "30px",
              },
            },
            InputLabelProps: {
              shrink: true,
            },
          },
        }}
        {...rest}
      />
    </div>
  </LocalizationProvider>
);

export default DatePicker;
