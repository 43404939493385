import {
  Card,
  TableContainer,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "components/shared";
import useAdminProfile from "./useAdminProfile";
import AppHeader from "components/Dashboard/components/Layout/AppHeader";

const AdminProfile = () => {
  const {
    rows,
    columns,
    approvedColumns,
    approvedRows,
    loading,
    error,
    snackbarMessage,
    snackbarSeverity,
    snackbarOpen,
    handleCloseSnackbar,
  } = useAdminProfile();

  return (
    <div>
      <AppHeader breadcrumbs="Users" page="Users" />

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="red">Error loading data: {error.message}</Typography>
      ) : (
        <>
          <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
            <TableContainer
              sx={{
                pl: 1,
                borderRadius: "15px",
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
              }}
            >
              <DataGrid
                columns={columns}
                rows={rows}
                loading={false}
                tableName="New User Request"
              />
            </TableContainer>
          </Card>

          <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
            <TableContainer
              sx={{
                pl: 1,
                borderRadius: "15px",
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
              }}
            >
              <DataGrid
                columns={approvedColumns}
                rows={approvedRows}
                loading={false}
              />
            </TableContainer>
          </Card>
        </>
      )}
      {/* Snackbar Alert */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminProfile;
