import AppHeader from "../components/Layout/AppHeader";
import {
  Card,
  TableContainer,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "components/shared";
import useTransactions from "./useTransactions";

const Transactions = () => {
  const { columns, depositRows, withdrawalRows, loading, error } =
    useTransactions();

  return (
    <div>
      <AppHeader breadcrumbs="Transactions" page="Transactions" />

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="red">Error loading data: {error.message}</Typography>
      ) : (
        <>
          <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
            <TableContainer
              sx={{
                pl: 1,
                borderRadius: "15px",
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
              }}
            >
              <DataGrid
                columns={columns}
                rows={[...depositRows, ...withdrawalRows].map(
                  (item, index) => ({ ...item, id: index + 1 })
                )}
                loading={false}
                tableName="Financial Transactions"
              />
            </TableContainer>
          </Card>

          <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
            <TableContainer
              sx={{
                pl: 1,
                borderRadius: "15px",
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
              }}
            >
              <DataGrid
                columns={columns}
                rows={depositRows.map((item, index) => ({
                  ...item,
                  id: index + 1,
                }))}
                loading={false}
                tableName="MyInvest Deposit Transactions"
              />
            </TableContainer>
          </Card>

          <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
            <TableContainer
              sx={{
                pl: 1,
                borderRadius: "15px",
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
              }}
            >
              <DataGrid
                columns={columns}
                rows={withdrawalRows.map((item, index) => ({
                  ...item,
                  id: index + 1,
                }))}
                loading={false}
                tableName="MyInvest Withdraw Transactions"
              />
            </TableContainer>
          </Card>
        </>
      )}
    </div>
  );
};

export default Transactions;
