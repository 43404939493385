import { ReactComponent as HeroImage } from "../../../../assets/Home/SVGs/HeroImg.svg";
import { TextField, Typography } from "@mui/material";
import Button from "../../../shared/Button";
import { ReactComponent as HeroArrow } from "../../../../assets/Home/SVGs/HeroArrow.svg";

const HeroSection = () => {
  return (
    <>
      <div className="flex flex-col items-center md:pt-10 pt-10 px-5 md:flex-row md:px-20 h-fit">
        <div className="flex flex-col gap-6 w-full">
          {/* Heading text with responsive font sizes */}
          <Typography
            variant="h4"
            className="font-bold text-darkblue-600 max-w-sm text-2xl md:text-4xl"
          >
            Unlock your Forex Trading Potential with Power of AI
          </Typography>

          {/* Body text with responsive font sizes */}
          <Typography variant="body1" className="text-base md:text-lg max-w-md">
            Wortex AI is an automated trading system based on AI (Artificial
            Intelligence). We are generating continuous profits from the forex
            market with the help of our AI Robot - Wortex AI. You can also
            invest in our Robo and start making enormous profit in the forex
            market.
          </Typography>

          {/* Input and Button */}
          <div className="flex flex-row">
            <TextField
              placeholder="Enter your email address"
              className="w-full md:max-w-xs"
              InputProps={{
                className: "rounded-r-none",
                sx: {
                  border: "none",
                  "&:before": {
                    border: "none", // Remove underline
                  },
                  "&:after": {
                    border: "none", // Remove underline on focus
                  },
                },
              }}
            />
            <Button
              variant="contained"
              className="rounded-l-none h-[56px]"
              color="secondary"
            >
              Get Started
            </Button>
          </div>
        </div>

        {/* Hero Image with responsive width */}
        <div>
          <HeroImage className="w-dvw md:w-auto" />
        </div>
      </div>

      {/* Hero Arrow */}
      <div className="flex w-full justify-center md:-mt-24 md:-ml-10">
        <HeroArrow />
      </div>
    </>
  );
};

export default HeroSection;
