import {
  Card,
  TableContainer,
  Typography,
  CircularProgress,
  Tabs,
  CardContent,
  Tab,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "components/shared";
import useFundRequest from "./useFundRequest";
import AppHeader from "components/Dashboard/components/Layout/AppHeader";

const FundRequest = () => {
  const {
    columns,
    depositRows,
    withdrawalRows,
    loading,
    error,
    snackbarMessage,
    snackbarSeverity,
    tabValue,
    snackbarOpen,
    handleTabChange,
    handleCloseSnackbar,
  } = useFundRequest();

  return (
    <div>
      <AppHeader breadcrumbs="FundRequest" page="FundRequest" />

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <Typography color="red">
            Error loading data: {error.message}
          </Typography>
          <Snackbar
            open={true}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert onClose={handleCloseSnackbar} severity="error">
              {error.message}
            </Alert>
          </Snackbar>
        </>
      ) : (
        <>
          <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none" }}>
            <CardContent>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Profile Tabs"
              >
                <Tab label="All Request" />
                <Tab label="Deposit" />
                <Tab label="Withdraw" />
              </Tabs>

              {/* All Requests */}
              {tabValue === 0 && (
                <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
                  <TableContainer
                    sx={{
                      pl: 1,
                      borderRadius: "15px",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "none",
                    }}
                  >
                    <DataGrid
                      columns={columns}
                      rows={[...depositRows, ...withdrawalRows].map(
                        (item, index) => ({ ...item, id: index + 1 })
                      )}
                      loading={false}
                    />
                  </TableContainer>
                </Card>
              )}

              {/* Deposit Requests */}
              {tabValue === 1 && (
                <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
                  <TableContainer
                    sx={{
                      pl: 1,
                      borderRadius: "15px",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "none",
                    }}
                  >
                    <DataGrid
                      columns={columns}
                      rows={depositRows.map((item, index) => ({
                        ...item,
                        id: index + 1,
                      }))}
                      loading={false}
                    />
                  </TableContainer>
                </Card>
              )}

              {/* Withdrawal Requests */}
              {tabValue === 2 && (
                <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
                  <TableContainer
                    sx={{
                      pl: 1,
                      borderRadius: "15px",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "none",
                    }}
                  >
                    <DataGrid
                      columns={columns}
                      rows={withdrawalRows.map((item, index) => ({
                        ...item,
                        id: index + 1,
                      }))}
                      loading={false}
                    />
                  </TableContainer>
                </Card>
              )}
            </CardContent>
          </Card>
        </>
      )}

      {/* Snackbar Alert */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FundRequest;
