import { useState, useEffect, useCallback } from "react";
import { getProfileData } from "utils/auth/getToken";
import { post } from "utils/axios"; // Assuming this is a wrapper around Axios

const useDeposit = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch transaction data
  const fetchTransactionData = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("transaction_type", "Deposit");
      formData.append("transaction_status", "All");

      const response = await post("/transaction_report", formData);
      const data = response?.transactions || [];

      const formattedRows = data.map((item, index) => ({
        id: index + 1,
        amount: item.amount,
        user: item.walletid,
        transaction_type: item.transaction_type,
        date: item.date,
        transaction_status: item.transaction_status,
      }));

      setRows(formattedRows);
    } catch (err) {
      setError(err.response || err);
    } finally {
      setLoading(false);
    }
  }, []);

  // Handle deposit request
  const submitDepositRequest = async (formData) => {
    try {
      const response = await post("/transaction_request", formData);
      return {
        success: true,
        message: response?.message ?? "Deposit successful",
      };
    } catch (error) {
      console.error("Deposit request failed:", error);
      return { success: false, message: "Failed to process deposit" };
    }
  };

  useEffect(() => {
    fetchTransactionData();
  }, [fetchTransactionData]);

  const columns = [
    { field: "id", headerName: "Transaction ID", flex: 1 },
    { field: "user", headerName: "User", flex: 1 },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter: (params) => `$${params}`,
    },
    { field: "transaction_type", headerName: "Transaction Type", flex: 1 },
    { field: "transaction_status", headerName: "Status", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
  ];
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("amount", values.amount);
    formData.append("walletid", values.walletAddress);
    formData.append("transaction_type", "Deposit");
    if (values.transactionId) {
      formData.append("transactionId", values.transactionId);
    }

    const result = await submitDepositRequest(formData);

    if (result.success) {
      // Construct new entry for immediate update
      const newEntry = {
        id: rows.length + 1,
        amount: values.amount,
        user: values.walletAddress,
        transaction_type: "Deposit",
        date: new Date().toLocaleDateString(), // or use the actual date format returned by the server if available
        transaction_status: "Pending", // Set the status based on your application’s logic
      };

      // Update rows immediately
      setRows((prevRows) => [newEntry, ...prevRows]);

      setSnackbarMessage("Deposit successful");
      setSnackbarSeverity("success");
      resetForm();
    } else {
      setSnackbarMessage("Failed to process deposit");
      setSnackbarSeverity("error");
    }

    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const { total_amount } = getProfileData();
  return {
    rows,
    columns,
    loading,
    error,
    submitDepositRequest,
    total_amount,
    handleSubmit,
    handleCloseSnackbar,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
  };
};

export default useDeposit;
