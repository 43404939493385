import { Box, Button, Card, Grid, TableContainer } from "@mui/material";
import Bonus from "../../assets/Profile/Bonus.png";
import Invested from "../../assets/Profile/Invested.png";
import LocAmount from "../../assets/Profile/LockAmount.png";
import Profit from "../../assets/Profile/Profit.png";
import Remaining from "../../assets/Profile/Remaining.png";
import AppHeader from "./components/Layout/AppHeader";
import { DataGrid } from "components/shared";
import Widget from "./components/Widget/Widget";
import useProfileHistory from "./useProfileHistory";
import { getProfileData } from "utils/auth/getToken";

function ProfileHistory() {
  const { columns, rows, handleClick } = useProfileHistory();
  const {
    total_deposit,
    total_withdrawn,
    total_referral_earning,
    total_amount,
    total_earning,
  } = getProfileData();
  return (
    <Box>
      <AppHeader
        breadcrumbs="FxTrado Profit History"
        page="FxTrado Profit History"
      />

      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={6} md={2.4}>
          <Widget image={CutOff} name="Next Cutoff Date" value="16-10-2024" />
        </Grid> */}

        <Grid item xs={6} sm={3} md={3} lg={2}>
          <Widget
            image={Invested}
            name="Total Amount"
            value={`$${total_amount}`}
          />
        </Grid>

        <Grid item xs={6} sm={3} md={3} lg={2}>
          <Widget
            image={LocAmount}
            name="Total Deposit"
            value={`$${total_deposit}`}
          />
        </Grid>

        <Grid item xs={6} sm={3} md={3} lg={2}>
          <Widget
            image={Bonus}
            name="Total Withdraw"
            value={`$${total_withdrawn}`}
          />
        </Grid>

        <Grid item xs={6} sm={3} md={3} lg={2}>
          <Widget
            image={Profit}
            name="Total Earnings"
            value={`$${total_earning}`}
          />
        </Grid>

        <Grid item xs={6} sm={3} md={3} lg={2}>
          <Widget
            image={Remaining}
            name="Referral Earnings"
            value={`$${total_referral_earning}`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item md={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "green", // Green background
              fontFamily: "Poppins",
              color: "white", // White text
              borderRadius: "8px", // Rounded corners
              textTransform: "none", // No capitalization
              width: "100%", // Full width
              "&:hover": {
                backgroundColor: "darkgreen", // Darker shade on hover
              },
            }}
            onClick={() => handleClick("deposit")}
          >
            Deposit
          </Button>
        </Grid>
        <Grid item md={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "blue", // Blue background
              color: "white", // White text
              borderRadius: "8px", // Rounded corners
              textTransform: "none", // No capitalization
              width: "100%", // Full width
              "&:hover": {
                backgroundColor: "darkblue", // Darker shade on hover
              },
              fontFamily: "Poppins",
            }}
            onClick={() => handleClick("withdraw")}
          >
            Withdraw
          </Button>
        </Grid>
        <Grid item md={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red", // Red background
              color: "white", // White text
              borderRadius: "8px", // Rounded corners
              textTransform: "none", // No capitalization
              width: "100%", // Full width
              "&:hover": {
                backgroundColor: "darkred", // Darker shade on hover
              },
              fontFamily: "Poppins",
            }}
          >
            Pause
          </Button>
        </Grid>
        <Grid></Grid>
      </Grid>
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <TableContainer
          sx={{
            pl: 1,
            borderRadius: "15px",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows ?? []}
            loading={false}
            tableName="Profit History"
          />
        </TableContainer>
      </Card>
    </Box>
  );
}

export default ProfileHistory;
