import { Card } from "@mui/material";

const Tree = () => {
  return (
    <div>
      <Card
        sx={{
          mt: 3,
          borderRadius: 4,
          boxShadow: "none",
          p: 2,
          backgroundColor: "#FFFFFF",
          height: "100%", // Ensure it fills the available height
        }}
      >
        Tree
      </Card>
    </div>
  );
};

export default Tree;
