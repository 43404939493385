import { post } from "./axios";

// Function to handle the login API call
export const login = async ({ email, password }) => {
  try {
    const payload = { email, password };
    const response = await post("/login", payload);

    // Store the login data in sessionStorage
    Object.entries(response.data).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });

    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

// Function to handle profile_details API call
export const getProfileDetails = async (token) => {
  try {
    const profileResponse = await post(
      "/profile_details/",
      { authToken: token },
      { headers: { authToken: token } }
    );

    // Store profile details in sessionStorage
    Object.entries(profileResponse.data).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });

    return profileResponse.data;
  } catch (error) {
    console.error("Error fetching profile details:", error);
    throw error;
  }
};
