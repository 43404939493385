import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Tabs,
  Tab,
  Avatar,
  IconButton,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { post } from "utils/axios";
import AppHeader from "components/Dashboard/components/Layout/AppHeader";
import { getProfileData } from "utils/auth/getToken";

// Validation schemas
const profileValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  mobile: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
});

const securityValidationSchema = Yup.object({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .min(6, "Password should be at least 6 characters long")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});
const ProfileDetails = (props) => {
  // Fetch user data from sessionStorasge and store in state
  const [userData, setUserData] = useState(getProfileData());
  const [tabValue, setTabValue] = useState(0);
  const [avatar, setAvatar] = useState("/path-to-avatar.jpg");

  // Monitor sessionStorage for changes and update the state
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedData = JSON.parse(sessionStorage.getItem("userData"));
      if (updatedData) {
        setUserData(updatedData);
      }
    };

    // Add an event listener to listen for storage changes
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result); // Set the new avatar source
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleUpdateProfileDetails = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await post("/updateuser", {
        token: userData.token,
        ...values,
      });

      // Update the userData object and save to sessionStorage
      const updatedProfileDetails = { ...userData, ...values };
      sessionStorage.setItem("userData", JSON.stringify(updatedProfileDetails));
      setUserData(updatedProfileDetails);
    } catch (error) {
      console.error("ProfileDetails update failed", error);
    }
    setSubmitting(false);
  };

  const handleChangePassword = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await post("/changepassword", {
        token: userData.token,
        old_pwd: values.currentPassword,
        new_pwd: values.newPassword,
      });
      console.log("Password change successful", response.data);
    } catch (error) {
      console.error("Password change failed", error);
    }
    setSubmitting(false);
  };

  return (
    <Box>
      {!props.isAdmin && (
        <AppHeader breadcrumbs="ProfileDetails" page="ProfileDetails" />
      )}

      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none" }}>
        <CardContent>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="ProfileDetails Tabs"
          >
            <Tab label="Edit ProfileDetails" />
            <Tab label="Security" />
          </Tabs>

          {/* Edit ProfileDetails Form */}
          {tabValue === 0 && (
            <Formik
              initialValues={{
                name: userData?.name || "",
                referal_code: userData?.referal_code || "",
                email: userData?.email || "",
                mobile: userData?.mobile || "",
                country: userData?.country || "",
              }}
              validationSchema={profileValidationSchema}
              onSubmit={handleUpdateProfileDetails}
              enableReinitialize
            >
              {({ isSubmitting, errors, touched, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={2}>
                      <Box
                        sx={{ position: "relative", display: "inline-block" }}
                      >
                        <Avatar
                          alt="User Avatar"
                          src={avatar}
                          sx={{ width: 120, height: 120, mb: 2 }}
                        />
                        <IconButton
                          color="primary"
                          aria-label="edit ProfileDetails picture"
                          sx={{
                            position: "absolute",
                            bottom: 20,
                            right: 0,
                            color: "white",
                            backgroundColor: "#2B3674",
                            borderRadius: "50%",
                            padding: 0.5,
                          }}
                          onClick={() => fileInputRef.current.click()}
                        >
                          <EditIcon />
                        </IconButton>
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef} // Attach the ref here
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <label>Your Name</label>
                          <Field
                            as={TextField}
                            fullWidth
                            name="name"
                            variant="outlined"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <label>Referral Code</label>
                          <Field
                            as={TextField}
                            fullWidth
                            name="referal_code"
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <label>Email</label>
                          <Field
                            as={TextField}
                            fullWidth
                            name="email"
                            variant="outlined"
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <label>Mobile Number</label>
                          <Field
                            as={TextField}
                            fullWidth
                            name="mobile"
                            variant="outlined"
                            error={touched.mobile && Boolean(errors.mobile)}
                            helperText={touched.mobile && errors.mobile}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <label>Country</label>
                          <Field
                            as={TextField}
                            fullWidth
                            name="country"
                            variant="outlined"
                            error={touched.country && Boolean(errors.country)}
                            helperText={touched.country && errors.country}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Saving..." : "Save"}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}

          {/* Security Section */}
          {tabValue === 1 && (
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={securityValidationSchema}
              onSubmit={handleChangePassword}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <Box sx={{ mt: 3, pl: 4 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <label>Current Password</label>
                        <Field
                          as={TextField}
                          fullWidth
                          name="currentPassword"
                          type="password"
                          variant="outlined"
                          error={
                            touched.currentPassword &&
                            Boolean(errors.currentPassword)
                          }
                          helperText={
                            touched.currentPassword && errors.currentPassword
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <label>New Password</label>
                        <Field
                          as={TextField}
                          fullWidth
                          name="newPassword"
                          type="password"
                          variant="outlined"
                          error={
                            touched.newPassword && Boolean(errors.newPassword)
                          }
                          helperText={touched.newPassword && errors.newPassword}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <label>Confirm New Password</label>
                        <Field
                          as={TextField}
                          fullWidth
                          name="confirmPassword"
                          type="password"
                          variant="outlined"
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? "Updating Password..."
                        : "Update Password"}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProfileDetails;
