import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Support } from "../../../../assets/Dashboard/Sidebar/Support.svg";
import { ReactComponent as FAQ } from "../../../../assets/Dashboard/Sidebar/FAQ.svg";
import { ReactComponent as SavingIcon } from "../../../../assets/Dashboard/Sidebar/Savings.svg";
import { ReactComponent as HistoryIcon } from "../../../../assets/Dashboard/Sidebar/History.svg";
import { ReactComponent as TransactionIcon } from "../../../../assets/Dashboard/Sidebar/Transactions.svg";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
} from "@mui/material";
import { Home, Person, ExpandLess, ExpandMore } from "@mui/icons-material";
import "../../../../style/Style.css";
import Logo from "../../../shared/Logo";

const Sidebar = () => {
  const location = useLocation();

  const [financialOpen, setFinancialOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);

  const handleFinancialClick = () => {
    setFinancialOpen(!financialOpen);
  };

  const handleInviteClick = () => {
    setInviteOpen(!inviteOpen);
  };

  const menuItems = [
    { text: "Dashboard", icon: <Home />, path: "/dashboard" },
    { text: "Profile", icon: <Person />, path: "/dashboard/profile" },
    {
      text: "Your Profit History",
      icon: <HistoryIcon />,
      path: "/dashboard/your-profit-history",
    },
    {
      text: "Financial Operation",
      icon: <SavingIcon />,
      path: "/dashboard/financial-operation/deposit",
      hasChildren: true,
      childPaths: [
        "/dashboard/financial-operation/deposit",
        "/dashboard/financial-operation/withdraw",
        "/dashboard/financial-operation/transfer",
      ],
    },
    {
      text: "Transaction",
      icon: <TransactionIcon />,
      path: "/dashboard/transactions",
    },
    {
      text: "Invite & Earn",
      icon: <SavingIcon />,
      path: "/dashboard/invite/link",
      hasChildren: true,
      childPaths: [
        "/dashboard/invite/link",
        "/dashboard/invite/team",
        "/dashboard/invite/income",
      ],
    },
    { text: "Support", icon: <Support />, path: "/dashboard/support" },
    { text: "FAQ's", icon: <FAQ />, path: "/dashboard/faqs" },
  ];

  const financialOperationItems = [
    { text: "Deposit", path: "/dashboard/financial-operation/deposit" },
    { text: "Withdraw", path: "/dashboard/financial-operation/withdraw" },
    {
      text: "Wallet Transfer",
      path: "/dashboard/financial-operation/transfer",
    },
  ];

  const referAndEarnItems = [
    { text: "Referral Link", path: "/dashboard/invite/link" },
    { text: "My Team", path: "/dashboard/invite/team" },
    { text: "Royalty Income", path: "/dashboard/invite/income" },
  ];

  const isActive = (path) => location.pathname === path;
  const isChildActive = (childPaths) =>
    childPaths.some((childPath) => location.pathname === childPath);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 290,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 290,
          boxSizing: "border-box",
          overflowY: "auto",
        },
      }}
      className="custom-scrollbar"
    >
      <Box className="pt-2 flex justify-center items-center">
        <Logo />
        <Box sx={{ borderBottom: "1px solid #ddd", my: 2 }} />
      </Box>
      <List>
        {menuItems.map((item) => (
          <div key={item.text}>
            {/* Parent Item */}
            <ListItem
              component={Link}
              to={item.path}
              sx={{
                backgroundColor:
                  isActive(item.path) && !item.hasChildren
                    ? "#e3f2fd"
                    : "transparent",
                color:
                  isActive(item.path) || isChildActive(item.childPaths || [])
                    ? "#2B3674"
                    : "#A3AED0",
                fontFamily: "Poppins",
              }}
              onClick={
                item.hasChildren
                  ? item.text === "Financial Operation"
                    ? handleFinancialClick
                    : handleInviteClick
                  : undefined
              }
            >
              <ListItemIcon
                sx={{
                  color:
                    isActive(item.path) || isChildActive(item.childPaths || [])
                      ? "#2B3674"
                      : "inherit",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  "& .MuiListItemText-primary": {
                    color:
                      isActive(item.path) ||
                      isChildActive(item.childPaths || [])
                        ? "#2B3674"
                        : "inherit",
                    fontWeight:
                      isActive(item.path) ||
                      isChildActive(item.childPaths || [])
                        ? "bold"
                        : "normal",
                  },
                }}
              />
              {item.hasChildren &&
                (item.text === "Financial Operation" ? (
                  financialOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : inviteOpen ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                ))}
            </ListItem>

            {/* Child Items */}
            {item.text === "Financial Operation" && (
              <Collapse in={financialOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {financialOperationItems.map((childItem) => (
                    <ListItem
                      key={childItem.text}
                      component={Link}
                      to={childItem.path}
                      sx={{
                        pl: 9,
                        backgroundColor: isActive(childItem.path)
                          ? "#e3f2fd"
                          : "transparent",
                        color: isActive(childItem.path) ? "#2B3674" : "#A3AED0",
                      }}
                    >
                      <ListItemText primary={childItem.text} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}

            {item.text === "Invite & Earn" && (
              <Collapse in={inviteOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {referAndEarnItems.map((childItem) => (
                    <ListItem
                      key={childItem.text}
                      component={Link}
                      to={childItem.path}
                      sx={{
                        pl: 9,
                        backgroundColor: isActive(childItem.path)
                          ? "#e3f2fd"
                          : "transparent",
                        color: isActive(childItem.path) ? "#2B3674" : "#A3AED0",
                      }}
                    >
                      <ListItemText primary={childItem.text} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
