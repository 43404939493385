import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import { POPPINS } from "./constants";

export const colorPalette = createTheme({
  palette: {
    primary: {
      main: colors.darkblue,
    },

    secondary: {
      main: colors.brightpink,
    },
  },
});

const theme = createTheme({
  // components
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: ${POPPINS};
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        },
        body {
          font-family: ${POPPINS};
          font-size:14px;
          background-color:${colors.background};
        },
      `,
    },

    // Buttons
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    // Button
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },

      styleOverrides: {
        disableElevation: {
          disableElevation: true,
        },
        root: {
          fontFamily: POPPINS,
          fontWeight: 700,
          textTransform: "unset",
          borderRadius: "6px",
          boxShadow: "none !important",
          letterSpacing: 0,
        },
      },
    },

    // Typography configuration
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.text,
        },
        h1: {
          fontSize: 96,
          fontWeight: 700,
          fontFamily: POPPINS,
        },
        h2: {
          fontSize: 60,
          fontWeight: 700,
          fontFamily: POPPINS,
        },
        h3: {
          fontSize: 48,
          fontWeight: 700,
          fontFamily: POPPINS,
        },
        h4: {
          fontSize: 32,
          fontWeight: 700,
          fontFamily: POPPINS,
          lineHeight: "40px",
        },
        h5: {
          fontSize: 24,
          fontWeight: 700,
          fontFamily: POPPINS,
          lineHeight: "32px",
        },
        h6: {
          fontSize: 20,
          fontWeight: 700,
          fontFamily: POPPINS,
        },
        subtitle1: {
          fontSize: 16,
          fontWeight: 400,
          fontFamily: POPPINS,
        },
        subtitle2: {
          fontSize: 14,
          fontWeight: 700,
          fontFamily: POPPINS,
        },
        body2: {
          fontSize: 12,
          fontWeight: 400,
          fontFamily: POPPINS,
          lineHeight: "20px",
        },
        body1: {
          fontSize: 14,
          fontWeight: 400,
          fontFamily: POPPINS,
        },
      },
    },
  },
});

export default theme;
