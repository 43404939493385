import {
  Avatar,
  Box,
  Card,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import AvtarIcon from "../../../../assets/Dashboard/Avtar.png";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { getProfileDetails } from "utils/signin";
import { getProfileData, getToken } from "utils/auth/getToken";

const AppHeader = ({ breadcrumbs, page }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchProfileData = async () => {
      const data = await getProfileDetails(getToken());

      // Sync the fetched profile data with sessionStorage
      Object.entries(data).forEach(([key, value]) => {
        sessionStorage.setItem(key, value);
      });
    };
    fetchProfileData();
  }, []);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/signin", { replace: true });
    window.location.reload();
    setAnchorEl(null);
  };

  // Get updated values from sessionStorage
  const totalAmount =
    sessionStorage.getItem("total_amount") ||
    sessionStorage.getItem("total_fund_amount");
  const { usertype } = getProfileData();
  const isAdmin = usertype === "admin" ? true : false;

  return (
    <Box sx={{ p: 3, display: { xs: "none", md: "block" } }}>
      <Grid container>
        <Grid item md={6}>
          <Typography
            gutterBottom
            sx={{ fontSize: 14, fontFamily: "Poppins", color: "#707EAE" }}
          >
            {breadcrumbs}
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: 30,
              fontFamily: "Poppins",
              fontWeight: 600,
              color: "#2B3674",
            }}
          >
            {page}
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 2,
              borderRadius: 2,
              boxShadow: "none",
            }}
          >
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              {!isAdmin && (
                <Grid item>
                  <Typography sx={{ fontFamily: "Poppins", color: "#A3AED0" }}>
                    Your balance
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      color: "#2B3674",
                    }}
                  >
                    ${totalAmount}
                  </Typography>
                </Grid>
              )}
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  alt="User Avatar"
                  src={AvtarIcon}
                  sx={{ width: 56, height: 56 }}
                />
                <IconButton onClick={handleMenuOpen}>
                  <ExpandMoreIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppHeader;
