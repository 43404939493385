import {
  Box,
  Button,
  Card,
  Grid,
  TableContainer,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomField from "components/shared/CustomField/CustomField";
import { DataGrid } from "components/shared";
import useTransfer from "./useTransfer";
import { getProfileData } from "utils/auth/getToken";

// Validation schema using Yup
const validationSchema = Yup.object({
  amount: Yup.number()
    .required("Amount is required")
    .min(1, "Amount should be more than 0"),
  recieverEmail: Yup.string().required("Reciever Email is required"),
  password: Yup.mixed().required("Transaction ID is required"), // Update to mixed for file input
});

function Transfer() {
  const { columns, rows } = useTransfer();
  const { total_amount } = getProfileData();
  return (
    <Box>
      {/* Wallet Transfer  */}
      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Left side: Wallet Transfer Text */}
          <Grid item md={6}>
            <Typography
              sx={{
                fontSize: "25px",
                fontFamily: "Poppins",
                color: "#2B3674",
                fontWeight: 600,
              }}
            >
              Wallet Transfer
            </Typography>
          </Grid>

          {/* Right side: Money bag icon and $1000 */}
          <Grid
            item
            md={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Card
              sx={{
                fontSize: "25px",
                fontFamily: "Poppins",
                color: "black",
                fontWeight: 600,
                borderRadius: 4,
                boxShadow: "none",
                backgroundColor: "#F4F7FE",
                p: 1,
                mb: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {total_amount}
              <FontAwesomeIcon
                icon={faSackDollar}
                style={{
                  marginLeft: "10px",
                  color: "#16DBCC",
                  backgroundColor: "white",
                  padding: 2,
                  borderRadius: 10,
                  width: "30px",
                }}
              />
            </Card>
          </Grid>
        </Grid>
        <hr />

        <Grid container>
          <Formik
            initialValues={{
              amount: "",
              recieverEmail: "example@gmail.com",
              password: "***",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log("Form data", values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <CustomField
                    name="recieverEmail"
                    type="email"
                    label="Reciever Email:"
                    error={
                      touched.recieverEmail && Boolean(errors.recieverEmail)
                    }
                    helperText={touched.recieverEmail && errors.recieverEmail}
                  />
                  <CustomField
                    name="amount"
                    type="number"
                    label="Amount:"
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                  <CustomField
                    name="password"
                    label="Password:"
                    type="passowrd"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      color: "white",
                      fontFamily: "Poppins",
                      mt: 2,
                      borderRadius: 2,
                      background: "#FBBC05",
                    }}
                  >
                    Transfer Request
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Card>

      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <TableContainer
          sx={{
            pl: 1,
            borderRadius: "15px",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows ?? []}
            loading={false}
            tableName="Wallet Transfer History"
          />
        </TableContainer>
      </Card>
    </Box>
  );
}

export default Transfer;
