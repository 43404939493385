import { Button as MuiButton } from "@mui/material";

function Button({ children, variant = "contained", ...rest }) {
  return (
    <MuiButton
      {...rest}
      variant={variant}
      className={`rounded-xl w-fit ${rest.className || ""}`}
    >
      {children}
    </MuiButton>
  );
}

export default Button;
