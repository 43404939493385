import axios from "axios";
import { getToken } from "./auth/getToken";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://fx-back.vercel.app/v1",
});

// Request interceptor to add token dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const get = async (url, params = {}) => {
  const response = await axiosInstance.get(url, { params });
  return response.data;
};

export const post = async (url, payload = {}, config) => {
  const response = await axiosInstance.post(url, payload, {
    headers: { authToken: getToken(), ...config?.headers },
    ...config,
  });
  return response.data;
};

export default axiosInstance;
