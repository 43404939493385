import React from "react";
import Logo from "../../../shared/Logo";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full px-6 md:px-20 py-10 flex flex-col gap-6 font-bold bg-white">
      <Logo />
      <div className="flex flex-col md:flex-row justify-between">
        {/* Text section */}
        <div className="max-w-xs">
          We are now active on all social media sites, including LinkedIn,
          Facebook, Instagram, Twitter, and YouTube. Please do not hesitate to
          contact us with queries.
        </div>

        {/* Overview and Legal - Shown on larger screens */}
        <div className="hidden md:flex flex-row gap-12 mt-4 md:mt-0">
          {/* Overview Section */}
          <div className="flex flex-col gap-3">
            <div className="text-blue font-bold">Overview</div>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/Dashboard">Dashboard</NavLink>
            <NavLink to="/#">About Us</NavLink>
            <NavLink to="/#">Contact Us</NavLink>
            <div className="mt-4">
              <span className="text-blue font-bold">Contact</span> :
              support.fxtrado@gmail.com
            </div>
          </div>

          {/* Legal Section */}
          <div className="flex flex-col gap-3">
            <div className="text-blue font-bold">Legal</div>
            <NavLink to="#">Privacy Policy</NavLink>
            <NavLink to="#">Terms of Service</NavLink>
          </div>
        </div>

        {/* Overview and Legal - Shown on mobile screens */}
        <div className="flex flex-row justify-between mt-6 md:hidden">
          {/* Overview Section */}
          <div className="flex flex-col gap-3">
            <div className="text-blue font-bold">Overview</div>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/Dashboard">Dashboard</NavLink>
            <NavLink to="/#">About Us</NavLink>
            <NavLink to="/#">Contact Us</NavLink>
          </div>

          {/* Legal Section */}
          <div className="flex flex-col gap-3">
            <div className="text-blue font-bold">Legal</div>
            <NavLink to="#">Privacy Policy</NavLink>
            <NavLink to="#">Terms of Service</NavLink>
          </div>
        </div>
        {/* Contact Section */}
        <div className="mt-4 md:hidden">
          <span className="text-blue font-bold">Contact</span> :
          support.fxtrado@gmail.com
        </div>
      </div>
    </div>
  );
};

export default Footer;
