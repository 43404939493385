import { Box } from "@mui/material";
import { Header } from "./components/Header";
import { HeroSection } from "./components/HeroSection";
import HeroBackground from "../../assets/Home/SVGs/HeroBG.svg";
import OfferBackground from "../../assets/Home/PNG/offers_bg.png";
import KnomoreBackground from "../../assets/Home/SVGs/KnowMoreBG.svg";
import OurExploreBackground from "../../assets/Home/SVGs/OurExploreBG.svg";
import Offers from "./components/Offers/Offers";
import Explore from "./components/Explore/Explore";
import Knowmore from "./components/Knowmore/Knowmore";
import FAQs from "./components/FAQs/FAQs";
import Services from "./components/Services/Services";
import Footer from "./components/Footer/Footer";

const Home = () => {
  return (
    <Box>
      <Box
        className="py-10"
        style={{
          backgroundImage: `url(${HeroBackground})`,
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Header />
        <HeroSection />
      </Box>
      <Box
        style={{
          backgroundImage: `url(${OfferBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
        }}
        className="md:mt-[-50px] mt-[-50px]"
      >
        <Offers />
      </Box>
      <Box
        style={{
          backgroundImage: `url(${KnomoreBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "fit-content",
        }}
      >
        <Knowmore />
      </Box>
      <Box
        sx={
          {
            // backgroundImage: {
            //   xs: "none", // No background image on small screens
            //   md: `url(${OurExploreBackground})`, // Apply background on medium and larger screens
            // },
            // backgroundPosition: "cover",
            // backgroundRepeat: "no-repeat",
            // width: "100vw",
          }
        }
        className="bg-white md:h-[900px] h-auto"
      >
        <Explore />
        <img
          src={OurExploreBackground}
          className="hidden md:block mt-[-25%] 2xl:mt-[-20%] w-screen"
        />
        <img src={OurExploreBackground} className="block md:hidden mt-[-10%]" />
      </Box>
      <div className="2xl:mt-[10%]">
        <FAQs />
      </div>
      <Services />
      <Footer />
      <div className="w-full text-center py-2">
        Copyright © 2024 FxTredo Company. All rights reserved.
      </div>
    </Box>
  );
};

export default Home;
