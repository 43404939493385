import { NavLink } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../assets/Home/SVGs/Logo.svg";

const Logo = ({ ...props }) => {
  return (
    <h1 className="text-5xl font-bold text-black md:text-4xl">
      <NavLink to="/">
        <LogoIcon {...props} />
      </NavLink>
    </h1>
  );
};

export default Logo;
