import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Snackbar,
  TableContainer,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import CustomField from "components/shared/CustomField/CustomField";
import { DataGrid } from "components/shared";
import AppHeader from "../components/Layout/AppHeader";
import { colors } from "style/colors";
import useSupport from "./useSupport";

// Validation schema using Yup
const validationSchema = Yup.object({
  description: Yup.string().required("Description is required"),
  subject: Yup.string().required("Subject is required"),
  support_file: Yup.mixed(), // Optional field for file attachment
});

function Support() {
  const {
    columns,
    rows,
    total_amount,
    handleSubmit,
    snackbarOpen,
    handleCloseSnackbar,
    snackbarSeverity,
    snackbarMessage,
  } = useSupport();

  return (
    <Box>
      <AppHeader breadcrumbs="Support" page="Support" />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={6}>
            <Typography
              sx={{
                fontSize: "25px",
                fontFamily: "Poppins",
                color: "#2B3674",
                fontWeight: 600,
              }}
            >
              New Support Ticket
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Card
              sx={{
                fontSize: "25px",
                fontFamily: "Poppins",
                color: "black",
                fontWeight: 600,
                borderRadius: 4,
                boxShadow: "none",
                backgroundColor: "#F4F7FE",
                p: 1,
                mb: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {total_amount}
              <FontAwesomeIcon
                icon={faSackDollar}
                style={{
                  marginLeft: "10px",
                  color: "#16DBCC",
                  backgroundColor: "white",
                  padding: 2,
                  borderRadius: 10,
                  width: "30px",
                }}
              />
            </Card>
          </Grid>
        </Grid>
        <hr />

        <Formik
          initialValues={{
            description: "",
            subject: "",
            support_file: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <CustomField
                  name="subject"
                  type="text"
                  label="Subject: "
                  error={touched.subject && Boolean(errors.subject)}
                  helperText={touched.subject && errors.subject}
                />
                <CustomField
                  name="description"
                  type="text"
                  label="Description:"
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
                <Grid item xs={12} md={6}>
                  <label>Image Attachments:</label>
                  <br />
                  <input
                    type="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("support_file", file);
                    }}
                    style={{
                      borderRadius: "8px",
                      padding: "8px",
                      border: "1px solid #ccc",
                      fontFamily: "Poppins",
                      width: "-webkit-fill-available",
                    }}
                  />
                  <ErrorMessage name="support_file" component="div" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "white",
                    fontFamily: "Poppins",
                    mt: 2,
                    borderRadius: 2,
                    background: colors.darkblue,
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>

      <Card sx={{ mt: 3, borderRadius: 4, boxShadow: "none", p: 2 }}>
        <TableContainer
          sx={{
            pl: 1,
            borderRadius: "15px",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows ?? []}
            loading={false}
            tableName="Support Tickets"
          />
        </TableContainer>
      </Card>
    </Box>
  );
}

export default Support;
