import { Box, Typography } from "@mui/material";
import { Button } from "components/shared";
import { useState, useEffect, useCallback } from "react";
import { post } from "utils/axios";

const useFundRequest = () => {
  const [depositRows, setDepositRows] = useState([]);
  const [withdrawalRows, setWithdrawalRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [tabValue, setTabValue] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const fetchTransactionData = useCallback(async (type) => {
    try {
      const formData = new FormData();
      formData.append("transaction_type", type);
      formData.append("transaction_status", "pending");

      const response = await post("/transaction_report", formData);
      return response?.transactions || [];
    } catch (err) {
      setError(err.response || err);
      return [];
    }
  }, []);

  const refetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const depositData = await fetchTransactionData("Deposit");
      const withdrawalData = await fetchTransactionData("Withdrawal");

      const formattedDepositRows = depositData.map((item, index) => ({
        transaction_id: index + 1,
        id: `deposit-${item.id}-${index}`,
        amount: item.amount,
        user: item.name,
        transaction_type: item.transaction_type,
        date: item.date,
        transaction_status: item.transaction_status,
        transactionID: item.transaction_id,
        userid: item.userid,
        walletid: item.walletid,
      }));

      const formattedWithdrawalRows = withdrawalData.map((item, index) => ({
        transaction_id: index + 1,
        id: `withdrawal-${item.id}-${index}`,
        amount: item.amount,
        user: item.name,
        transaction_type: item.transaction_type,
        date: item.date,
        transaction_status: item.transaction_status,
        transactionID: item.transaction_id,
        userid: item.userid,
        walletid: item.walletid,
      }));

      setDepositRows(formattedDepositRows);
      setWithdrawalRows(formattedWithdrawalRows);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [fetchTransactionData]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const handleFundAction = async (fund, type) => {
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("txnid", fund.transaction_id);
      formData.append("userid", fund.userid);
      formData.append("walletid", fund.walletid);
      formData.append("transaction_status", type);
      formData.append("description", fund.description);

      await post("/transaction_approved", formData);

      // Refetch the updated data
      await refetchData();

      // Show success Snackbar
      setSnackbarOpen(true);
      setSnackbarMessage(`${type} successfully`);
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(`Failed to ${type}: ${error.message}`);
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      flex: 1,
    },
    { field: "user", headerName: "User", flex: 1 },
    { field: "walletid", headerName: "Wallet ID", flex: 1 },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body1"
          mt={2}
          fontWeight="bold"
          color={params.row.transaction_type === "Deposit" ? "green" : "red"}
        >
          ${params.row.amount}
        </Typography>
      ),
    },
    { field: "transaction_type", headerName: "Transaction Type", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box className="flex flex-col md:flex-row justify-between gap-0 m-0 p-0 md:gap-2 md:m-2 md:p-1">
          <Button
            className="!text-white text-[8px] p-0 w-fit md:text-sm md:p-1" // 8px text size with 2px padding
            onClick={() => {
              handleFundAction(
                {
                  ...params.row,
                  userid: params.row.userid,
                  walletid: params.row.walletid,
                  transaction_id: params.row.transactionID,
                  description: "Action from fund request",
                },
                "Approved"
              );

              setSnackbarMessage("Approved");
              setSnackbarSeverity("success");
            }}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            className=" text-[8px] p-0 w-fit md:text-sm md:p-1" // 8px text size with 2px padding
            onClick={() => {
              console.log(params.row);
              handleFundAction(
                {
                  ...params.row,
                  userid: params.row.userid,
                  walletid: params.row.walletid,
                  transaction_id: params.row.transactionID,
                  description: "Action from fund request",
                },
                "Rejected"
              );
              setSnackbarOpen(true);
              setSnackbarMessage("Rejected");
              setSnackbarSeverity("success");
            }}
          >
            Reject
          </Button>
        </Box>
      ),
    },
  ];

  return {
    depositRows,
    withdrawalRows,
    columns,
    loading,
    error,
    snackbarMessage,
    snackbarSeverity,
    tabValue,
    snackbarOpen,
    handleTabChange,
    handleCloseSnackbar,
  };
};

export default useFundRequest;
