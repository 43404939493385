import PropTypes from "prop-types";
import { useState, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";

// chart options
const areaChartOptions = {
  chart: {
    height: 450,
    type: "line", // Ensure it is a line chart
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth", // Smooth line curve
    width: 2, // Set stroke width to show both lines clearly
  },
  grid: {
    show: false, // Disable background grid
  },
  xaxis: {
    axisBorder: {
      show: false, // Hide the x-axis line
    },
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ], // Display months
    tooltip: {
      enabled: false, // Disable crosshair
    },
  },
  tooltip: {
    enabled: true,
    shared: false, // Show tooltips separately for each line
    x: {
      show: false, // Hide the month name in the tooltip
    },
    y: {
      formatter: (value, { seriesIndex }) => {
        return seriesIndex === 0 ? `${value}$ Deposit` : `${value}$ Profit`; // Show Deposit/Profit in tooltip
      },
    },
    crosshairs: {
      show: false, // Disable vertical crosshair line
    },
  },
  colors: ["#0556C2", "#6AD2FF"], // Custom colors for Deposit and Profit
};

// ==============================|| INCOME AREA CHART ||============================== //

export default function IncomeAreaChart({ slot }) {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: ["#0556C2", "#6AD2FF"], // Ensure colors for Deposit and Profit are applied
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          style: {
            colors: Array(12).fill(secondary),
          },
        },
        axisBorder: {
          show: false,
        },
        tickAmount: 11,
        tooltip: {
          enabled: false, // Disable crosshair tooltip
        },
      },
      yaxis: {
        show: false, // Hide Y-axis
      },
    }));
  }, [primary, secondary, line, theme]);

  const [series, setSeries] = useState([
    {
      name: "Deposit", // Label for Deposit
      data: [500, 450, 550, 600, 500, 650, 700, 600, 800, 700, 900, 1000], // Updated data for Deposit
    },
    {
      name: "Profit", // Label for Profit
      data: [300, 350, 500, 700, 600, 550, 400, 450, 600, 700, 800, 1200], // Updated data for Profit
    },
  ]);

  useEffect(() => {
    setSeries([
      {
        name: "Deposit",
        data:
          slot === "month"
            ? [500, 450, 550, 600, 500, 650, 500, 600, 650, 700, 800, 900] // Monthly data for Deposit
            : [1000, 1150, 1300, 1450, 1600, 1750, 1900], // Weekly data for Deposit
      },
      {
        name: "Profit",
        data:
          slot === "month"
            ? [300, 350, 500, 700, 600, 550, 400, 650, 750, 800, 900, 1200] // Monthly data for Profit
            : [500, 600, 700, 800, 900, 1000, 1100], // Weekly data for Profit
      },
    ]);
  }, [slot]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line" // Ensure the chart type is line
      height={225}
    />
  );
}

IncomeAreaChart.propTypes = { slot: PropTypes.string };
