import { Typography } from "@mui/material";
import { ReactComponent as HandGift } from "../../../../assets/Home/SVGs/hand-holding-gift-svgrepo-com.svg";
import { ReactComponent as HandHeart } from "../../../../assets/Home/SVGs/hand-holding-heart-svgrepo-com.svg";
import { ReactComponent as MoneyBag } from "../../../../assets/Home/SVGs/money-bag-svgrepo-com.svg";

const offers = [
  {
    icon: <HandGift className="size-8 fill-white" />,
    title: "Risk Management Solutions!",
    description:
      "We offer comprehensive risk management solutions tailored to your Forex trading needs. Safeguard your investments with our advanced tools and expertise, ensuring greater stability and profitability in volatile markets.",
  },
  {
    icon: <MoneyBag className="size-8 fill-white" />,
    title: "Reputable Liquidity Providers!",
    description:
      "Wortex AI partners with reputable liquidity providers to ensure seamless Forex trading experiences. Enjoy access to deep liquidity pools and reliable execution, enhancing your trading performance with confidence boosters.",
  },
  {
    icon: <HandHeart className="size-8 fill-white" />,
    title: "User Friendly Interface!",
    description:
      "We offer a user-friendly interface designed for seamless navigation and intuitive trading experiences. Streamline your Forex journey with easy-to-use tools and accessible features, empowering you to invest more in market.",
  },
  {
    icon: <MoneyBag className="size-8 fill-white" />,
    title: "Verified Track Record!",
    description:
      "We boast a verified track record of success in Forex trading, backed by transparent performance metrics. Trust in our proven expertise to guide your investment journey and achieve your financial goals with certainty.",
  },
];

const Offer = ({ icon, title, description }) => {
  return (
    <div className="border-2 border-blue bg-white shadow-slate-500 shadow-xs rounded-lg p-4 flex flex-col justify-center items-center gap-4 max-w-xs max-h-xs overflow-hidden">
      <div className="bg-blue rounded-full p-4">{icon}</div>
      <Typography
        variant="body1"
        className="font-bold text-md text-[16px] text-center"
      >
        {title}
      </Typography>
      <Typography variant="description" className="text-center text-[12px]">
        {description}
      </Typography>
    </div>
  );
};

const Offers = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center text-center pt-20 px-5 md:px-20">
      <div className="text-brightpink font-bold py-3 px-8 border-2 border-brightpink bg-brightpink-50 rounded-3xl w-fit justify-center flex items-center">
        Our Offers
      </div>
      <Typography
        variant="h4"
        className="font-bold text-darkblue max-w-sm mt-6 text-[32px] md:text-4xl"
      >
        Some standard deals for forex trades
      </Typography>
      <div className="relative w-full pt-8 pb-4 scrollbar-hide">
        {/* Outer div to control the visible area */}
        <div className="w-full overflow-x-auto scrollbar-hide">
          {/* Inner div to ensure scrolling and fixed width */}
          <div
            className="flex gap-1 md:gap-4 scrollbar-hide"
            style={{ width: "calc(290px * 1.1)" }} // For mobile, show 1 card
          >
            {offers.map((offer, index) => (
              <div key={index} className="min-w-[290px] md:min-w-[425px]">
                <Offer {...offer} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
